import {createSlice, current} from "@reduxjs/toolkit";
import {AUTH} from "../../auth/FirebaseContext";
import {CALC_OPERATION, ORDER_TYPE} from "../../constants";
import moment from "moment/moment";

// ----------------------------------------------------------------------

const initialState = {
    order: {
        active: false,
        alert: false,
        cashier: null,
        items: [],
        finishedPreparation: false,
        id: null,
        orderTime: null,
        isMobile: false,
        orderType: ORDER_TYPE.all,
        submitted: false,
        tableUid: null,
        userUid: null,
        seen: true,
        printed: false,
    }
};

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        seeOrder: (state) => {
            const order = {...current(state.order)};
            order.seen = true
            order.alert = false
            state.order = order;
        },
        updateOrder: {},
        createOrder: (state, {payload}) => {

            const momentTimestamp = moment();

            const jsDate = momentTimestamp.toDate();
            state.order = {
                active: true,
                cashier: payload.cashier,
                items: [],
                finishedPreparation: false,
                id: Date.now(),
                orderTime: jsDate,
                isMobile: payload.isMobile,
                orderType: ORDER_TYPE.all,
                submitted: false,
                tableUid: payload.uid,
                userUid: AUTH.currentUser.uid,
                seen: true,
                printed: false,
            }
        },
        deleteOrder: (state, {payload}) => {
        },
        updateOrderItem: (state, {payload}) => {
            const order = {...current(state.order)};
            const items = [...order.items];
            const index = items.findIndex((item) => item.uid === payload.uid);
            if (index !== -1) {
                items[index] = updateItem(items[index], payload.calcOperation, payload?.customQuantity);

                if (items[index].quantity <= 0) {
                    items.splice(index, 1);
                }
            } else {
                const newItem = createNewItem(payload);
                items.push(newItem);
            }
            order.items = items;
            state.order = order;
        },
        updateOrderNote: (state, {payload}) => {
            const order = {...current(state.order)};
            const items = [...order.items];

            payload?.items?.forEach((note) => {
                const index = items.findIndex((item) => item.uid === note.uid);
                if (index !== -1) {
                    items[index] = updateNote(items[index], note.note);
                }
            });

            order.items = items;
            state.order = order;
        },
        // submitOrder: (state) => {
        //     const order = {...current(state.order)};
        //     order.submitted = true;
        //     console.log("pozvannnnnnn")
        //     state.order = order;
        // },
        resetOrder: (state) => {
            state.order = initialState.order
        }
    }
})

function updateNote(item, note) {
    const copyItem = {...item};
    if (note) {
        copyItem.note = note;
    }
    return copyItem;
}

function updateItem(item, calcOperation, customQuantity) {
    const copyItem = {...item};
    if (customQuantity) {
        copyItem.quantity = customQuantity
    } else {
        copyItem.quantity = calcOperation === CALC_OPERATION.SUM ? item.quantity + 1 : item.quantity - 1;
    }
    return copyItem;
}

function createNewItem(payload) {
    const obj = {
        quantity: 1,
        unit: payload?.unit,
        ingredients: payload?.ingredients || null,
        restaurantItemsCategory: payload?.restaurantItemsCategory || null,
        isNormativ: payload?.isNormativ || false,
        prepTime: Number(payload.prepTime) || 0,
        price: Number(payload.price),
        name: payload.name,
        vat: payload.vat,
        uid: payload.uid,

        category: payload.category,
        restaurantGroup: payload.restaurantGroup,
    };
    if(payload?.ean){
        obj.ean = payload.ean
    }
    if(payload?.code){
        obj.code = payload.code
    }
    return obj;
}

export const {createOrder, updateOrderNote, resetOrder, updateOrderItem, seeOrder} = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
