import PropTypes from "prop-types";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
// @mui
import {Box, Drawer, Stack, Typography} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import {NAV} from "../../../config";
// components
import Scrollbar from "../../../components/Scrollbar";
import {NavSectionVertical} from "../../../components/nav-section";
//
import navConfig from "./config";

import NavAccount from "./NavAccount";
import {useAuthContext} from "../../../auth/useAuthContext";
import useActiveLink from "../../../hooks/useActiveLink";
import {PATH_DASHBOARD} from "../../../routes/path";
import {useLocationContext} from "../../../context/Location";

// ----------------------------------------------------------------------

NavVertical.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func
};

export default function NavVertical({openNav, onCloseNav}) {

    const {pathname} = useLocation();
    //TODO OBRISATI JER CE BITI OK DISPLAY NAME U BUDUCNOSTI
    const {locationState} = useLocationContext();

    let isDesktop = useResponsive("up", "lg");
    const {active} = useActiveLink(PATH_DASHBOARD.general.app);

    if (active) {
        isDesktop = false;
    }

    const {user} = useAuthContext();

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);


    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column"
                }
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0
                }}>
                {/*<Logo />*/}
                <Typography sx={{
                    ml: 1,
                    fontWeight: 700
                }}>{locationState.short === "BA" ? "IT Creator" : user.displayName}</Typography>
                <NavAccount/>
            </Stack>

            <NavSectionVertical data={navConfig}/>

            <Box sx={{flexGrow: 1}}/>

        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                display: active ? "none" : null,
                flexShrink: {lg: 0},
                width: {lg: NAV.W_DASHBOARD}
            }}>
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD,
                            bgcolor: "transparent",
                            borderRightStyle: "dashed"
                        }
                    }}>
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true
                    }}
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD
                        }
                    }}>
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
