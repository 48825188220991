import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {child, get, push, update, remove} from "firebase/database";
import {getClientInfo} from "../../utils/axios";

// ----------------------------------------------------------------------

export const fetchAllCustomers = createAsyncThunk('fetchAllCustomers', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers`))).forEach(child1 => {
        arr.push({
            ...child1.val(),
            uid: child1.key
        })
    })
    return arr;
})

export const getClientDetails = createAsyncThunk('getClientDetails', async (tin) => {
    const response = await getClientInfo(tin);
    return response.data;
})
// vat je pib
export const addCustomer = createAsyncThunk('addCustomer', async (customer) => {
    push(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers`), customer);
    return customer
})

export const updateCustomer = createAsyncThunk('updateCustomer', async (customer) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers/${customer.uid}`), customer);
    return customer
})

export const removeCustomer = createAsyncThunk('removeCustomer', async (customer) => {
    await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers/${customer.uid}`));
    return customer
})
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    allCustomers: [],
    customer: null,
}

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    extraReducers: {
        [fetchAllCustomers.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllCustomers.fulfilled]: (state, action) => {
            state.allCustomers = action.payload
            state.loading = false;
        },
        [fetchAllCustomers.rejected]: (state) => {
            state.loading = false;
        },
        [addCustomer.pending]: (state) => {
            state.loading = true;
        },
        [addCustomer.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allCustomers)];
            arr.push(payload)
            state.allCustomers = arr
            state.loading = false;
        },
        [addCustomer.rejected]: (state) => {
            state.loading = false;
        },
        [updateCustomer.pending]: (state) => {
            state.loading = true;
        },
        [updateCustomer.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allCustomers)]
            const index = arr.findIndex(o => o.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.allCustomers = arr
            }
            state.loading = false;
        },
        [updateCustomer.rejected]: (state, action) => {
            state.loading = false;
        },
        [removeCustomer.pending]: (state) => {
            state.loading = true;
        },
        [removeCustomer.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allCustomers)]
            const index = arr.findIndex(o => o.uid === payload.uid)
            if (index !== -1) {
                arr.splice(index, 1)
                state.allCustomers = arr
            }
            state.loading = false;
        },
        [removeCustomer.rejected]: (state, action) => {
            state.loading = false;
        },
    }
})

export const customersReducer = customersSlice.reducer
