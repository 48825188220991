import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, MenuItem, Stack, Typography } from "@mui/material";
// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../../routes/path";
// auth
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { useSnackbar } from "notistack";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import { clearSession, getSessionOperator } from "../../../helper/session";
import { findRoleName } from "../../../helper/other";
import { OPERATORS_GROUP } from "../../../constants";

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: "Početna",
    linkTo: "/"
  },
  {
    label: "Podešavanja",
    linkTo: PATH_DASHBOARD.general.settings
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const operator = getSessionOperator();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      // logout();
      clearSession(false)
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Došlo je do greške!", { variant: "error" });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}>
        <CustomAvatar src={operator?.photoURL} alt={operator?.username} name={operator?.username} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {operator?.username}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {findRoleName(operator?.group)}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {[OPERATORS_GROUP.admin, OPERATORS_GROUP.manager].includes(operator?.group) &&
          <Stack sx={{ p: 1 }}>
            {OPTIONS.map((option) => (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                {option.label}
              </MenuItem>
            ))}
            <Divider sx={{ borderStyle: "dashed" }} />
          </Stack>
        }
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Odjavi se
        </MenuItem>
      </MenuPopover>
    </>
  );
}
