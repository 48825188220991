import PropTypes from 'prop-types';
import {m} from 'framer-motion';
// @mui
import {Container, Typography} from '@mui/material';
import MotionContainer from "../components/animate/MotionContainer";
import {varBounce} from "../components/animate/variants";
import ForbiddenIllustration from "../assets/illustratuions/ForbiddenIllustration";
import {getSessionOperator} from "../helper/session";
import {useLocales} from "../locales";
// components


// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
    hasContent: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.number),
};

export default function RoleBasedGuard({hasContent, roles, children}) {

    const operator = getSessionOperator();
    const {translate} = useLocales();
    if (typeof roles !== 'undefined' && !roles.includes(operator?.group)) {
        return hasContent ? (
            <Container component={MotionContainer} sx={{textAlign: 'center'}}>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                        {translate("forbidden")}
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{color: 'text.secondary'}}>{translate("noAcces")}</Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <ForbiddenIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
                </m.div>
            </Container>
        ) : null;
    }

    return <>{children}</>;
}
