import { clearSession, setSessionAppVersion } from "../session";
import {INVOICE_TYPE, ORDER_TYPE, TRANSACTION_TYPE} from "../../constants";
import { productsImageStorage } from "../../auth/FirebaseContext";
import { getDownloadURL, ref } from "firebase/storage";
import {runInContext as ONLY_TIN} from "lodash";

export const isEmpty = (value) => {
    return value === undefined || value === null || value === "";
};

export const getCustomerIndetification = (customer, isBH = false) => {
    if (isBH) {
        if (!isEmpty(customer.tin) && isEmpty(customer.jbkjc)) {
            return customer.tin;
        } else if (!isEmpty(customer.tin) && !isEmpty(customer.jbkjc)) {
            return `${customer.tin}:${customer.jbkjc}`;
        } else if (!isEmpty(customer.jmbg)) {
            return customer.jmbg;
        } else {
            return customer.otherIndificationNumber;
        }
    }
    const taxCorePrefix = parseInt(customer.taxCorePrefix);
    if (ONLY_TIN.includes(taxCorePrefix)) {
        return `${taxCorePrefix}:${customer.tin}`;
    } else if (taxCorePrefix === 11) {
        return `${taxCorePrefix}:${customer.jmbg}`;
    } else if (taxCorePrefix === 12) {
        return `${taxCorePrefix}:${customer.tin}:${customer.jbkjc}`;
    }
    return `${taxCorePrefix}:${customer.otherIndificationNumber}`;
};

export const getCustomerByBuyerTin = (customers, buyerTin) => {
    if (!buyerTin) {
        return;
    }
    const split = buyerTin.split(":");
    return customers.find(customer => {
        if (split.length === 3) {
            return (customer.taxCorePrefix === Number(split[0]) &&
                customer.jbkjc === split[2] &&
                (customer.jmbg === split[1] || customer.tin === split[1]));
        }
        return customer.taxCorePrefix === Number(split[0]) &&
            (customer.jmbg === split[1] || customer.tin === split[1]);
    });
};

export function get_browser() {
    let ua = window.navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) {
            return {name: 'Opera', version: tem[1]};
        }
    }
    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
}

export function getTaxDetailsByLabel(label, currentTaxRates) {
    for (let obj of currentTaxRates) {
        for (let taxRate of obj.taxRates) {
            if (taxRate.label === label) {
                return {
                    name: obj.name,
                    rate: taxRate.rate,
                    label: taxRate.label
                };
            }
        }
    }
    return null;
}

export function sortByDateDescending(array) {
    return array.sort((a, b) => {
        const dateA = new Date(a.sdcDateTime);
        const dateB = new Date(b.sdcDateTime);
        return dateB - dateA;
    });
}

export function getCounterFromInvoice(invoice) {
    let invoiceNumber = invoice.invoiceNumber;
    let index = invoiceNumber.lastIndexOf("-") + 1
    let counter = invoiceNumber.substring(index, invoiceNumber.length)
    let firstLetter;
    switch (invoice.invoiceType) {
        case INVOICE_TYPE.training:
            firstLetter = "О"
            break;
        case INVOICE_TYPE.copy:
            firstLetter = "К"
            break;
        case INVOICE_TYPE.normal:
            firstLetter = "П"
            break;
        case INVOICE_TYPE.advance:
            firstLetter = "А"
            break;
        case INVOICE_TYPE.proforma:
            firstLetter = "Р"
            break;
        default:
            firstLetter = "#"
            break;
    }
    let secondLetter = invoice.transactionType === TRANSACTION_TYPE.sale ? "П" : "Р";
    return `${counter}/${firstLetter}${secondLetter}`
}
export function clearCache(appVersion) {
    if ('caches' in window) {
        caches.keys().then(async (names) => {
            // Delete all the cache files
            for (const name of names) {
                await caches.delete(name);
            }
        });
    }
    clearSession()
    window.location.reload(true);
    setSessionAppVersion(appVersion)
}

export function taxValueForLabel(item, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        const found = currentTaxRates[i].taxRates.find(ct => ct.label === item.vat)
        if (found) {
            tax = found.rate
        }
    }
    return tax
}

export function taxValueForSingleLabel(label, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        const found = currentTaxRates[i].taxRates.find(ct => ct.label === label)
        if (found) {
            tax = found.rate
        }
    }
    return tax
}

export function getTaxValueByLabel(label, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        if (currentTaxRates[i].taxRates[0].label === label) {
            tax = currentTaxRates[i].taxRates[0].rate
        }
    }
    return tax
}

export function calculateBasicOfItem(item, currentTaxRates) {
    let tax = taxValueForLabel(item, currentTaxRates)
    return (parseFloat(item.unitPrice) / (tax * 0.01 + 1)) *
        parseFloat(item.addedQuantity  || item.quantity)
}

export const calculateMarkUp = (purchasePrice, sellPrice) => {
    if (purchasePrice === 0) {
        return 0;
    }
    return ((sellPrice - purchasePrice) / purchasePrice) * 100;
};


export function calculateBasicOfSingleItem(item, currentTaxRates) {
    let tax = taxValueForLabel(item, currentTaxRates)
    return (parseFloat(item.unitPrice) / (tax * 0.01 + 1)).toFixed(2)
}

export function calculateBasic(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        let tax = taxValueForLabel(items[i], currentTaxRates)
        sum += (parseFloat(items[i].unitPrice) / (tax * 0.01 + 1)) *
            parseFloat(items[i].addedQuantity || items[i].quantity)
    }
    return sum;
}

export function isArrayContainsTax(array, tax) {
    let index = -1;
    if (!array)
        return index;
    if (array.length === 0)
        return index;
    for (let i = 0; i < array.length; i++) {
        if (array[i].label === tax.label) {
            index = i;
            return index
        }
    }
    return index;
}

export function calculatePdv(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        sum += parseFloat(items[i].unitPrice) *
            parseFloat(items[i].addedQuantity || items[i].quantity) - calculateBasicOfItem(items[i], currentTaxRates)
    }
    return sum;
}

export function convertItemName(name) {
    const chars = {'č': 'c', 'Č': 'C', 'ć': 'c', 'Ć': 'c', 'đ': 'dj', 'Đ': 'Đ', 'š': 's', 'Š': 'S', 'ž': 'z', 'Ž': 'Z'}
    return name?.replace(/[čČćĆđĐšŠžŽ]/g, m => chars[m])
}

export function convertVatFromLatToCyr(vat, allLabels) {
    const chars = {
        'a': 'а', 'b': 'б', 'c': 'ц', 'č': 'ч', 'ć': 'ћ', 'd': 'д', 'đ': 'ђ', 'e': 'е',
        'f': 'ф', 'g': 'г', 'h': 'х', 'i': 'и', 'j': 'ј', 'k': 'к', 'l': 'л', 'lj': 'љ',
        'm': 'м', 'n': 'н', 'nj': 'њ', 'o': 'о', 'p': 'п', 'r': 'р', 's': 'с', 'š': 'ш',
        't': 'т', 'u': 'у', 'v': 'в', 'z': 'з', 'ž': 'ж',
        'A': 'А', 'B': 'Б', 'C': 'Ц', 'Č': 'Ч', 'Ć': 'Ћ', 'Dj': 'Ђ', 'D': 'Ђ', 'Đ': 'Ђ', 'E': 'Е',
        'F': 'Ф', 'G': 'Г', 'H': 'Х', 'I': 'И', 'J': 'Ј', 'K': 'К', 'L': 'Л', 'Lj': 'Љ',
        'M': 'М', 'N': 'Н', 'Nj': 'Њ', 'O': 'О', 'P': 'П', 'R': 'Р', 'S': 'С', 'Š': 'Ш',
        'T': 'Т', 'U': 'У', 'V': 'В', 'Z': 'З', 'Ž': 'Ж'
    };
    const letter = vat?.replace(/Dj|dj|[a-zA-ZČčĆćŠšŽžLlNn]/g, m => chars[m] || m).toUpperCase();
    if(allLabels.some(i => i === letter)){
        return letter
    } else return undefined
}


export function isArrayContainsTaxA4(arr, item) {
    let index = -1
    if (!arr)
        return index;
    if (arr.length === 0)
        return index;
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].label.length; j++) {
            for (let k = 0; k < item.labels.length; k++) {
                if (arr[i].label[j] === item.labels[k]) {
                    return i
                }
            }
        }
    }
    return index;
}

export const findRoleName = (role) => {
    switch (role){
        case 0:
            return "Administrator"
        case 1:
            return "Menadžer"
        case 2:
            return "Korisnik"
        case 3:
            return "Knjigovođa"
        case 8:
            return "Kuvar"
        case 9:
            return "Konobar"
        case 10:
            return "Šanker"
        default:
            return "Ostalo"
    }
}

// ZA IZVESTAJ PO STOLU DA BI PRIKAZAO NAZIV LAYOUTA

export function transformString(inputString) {

    let result = inputString.charAt(0).toUpperCase();

    for (let i = 1; i < inputString.length; i++) {
        if (inputString[i] === inputString[i].toUpperCase()) {
            result += ' ' + inputString[i].toLowerCase();
        } else {
            result += inputString[i];
        }
    }
    result = result.replace(/\d+$/, '');

    return result;
}

export const fetchImageUrlAsync = async (uid) => {
    const imageRef = ref(productsImageStorage, `${uid}.png`);
    const downloadURL = await getDownloadURL(imageRef);
    // Fetch the image data as Blob
    let blob;
    try {
        const response = await fetch(downloadURL);
        blob = await response.blob();
    } catch (e) {
    }
    return {
        url: downloadURL,
        blobImage: blob
    };
};

export function checkRestaurantGroups(order) {
    const hasKuhinja = order.items.some(item => item.restaurantGroup === "Kuhinja");
    const hasSank = order.items.some(item => item.restaurantGroup === "Šank");

    if (hasKuhinja && hasSank) {
        return ORDER_TYPE.all;
    } else if (order.items.every(item => item.restaurantGroup === "Kuhinja")) {
        return ORDER_TYPE.kitchen;
    } else if (order.items.every(item => item.restaurantGroup === "Šank")) {
        return ORDER_TYPE.bar;
    } else {
        return 0;
    }
}

