import React from "react"
import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
import {useLocales} from "../../locales";

export default function LicenseExpiredDialog({open}) {
    const {translate} = useLocales();

    return (
        <Dialog open={open}>
            <Stack alignItems="center">
                <Typography style={{fontWeight: "bold"}}>{translate("esirSupport")}</Typography>
            </Stack>
            <DialogContent>
                <Typography>
                    {translate("licenseExpiredMessage")}
                </Typography>
                <Typography>
                    {translate("callTenantMessage")}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}
