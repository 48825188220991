import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { get50RecordsOnRealTimeDB } from "../../helper/realtimeDatabase";
import moment from "moment";
import { child, get, ref, update } from "firebase/database";
import { AUTH, DB, dbRef, RDB } from "../../auth/FirebaseContext";
import { getSessionOperator } from "../../helper/session";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";

export const fetchAllInventoryItems = createAsyncThunk("fetchAllInventoryItems", async () => {
    const userItemsDbRef = ref(RDB, `users/${AUTH.currentUser.uid}/private/items`);
    let items = [];
    let lastData = "";
    await get50RecordsOnRealTimeDB(userItemsDbRef, lastData, []).then(async value => {
        for (const item of value) {
            let publicItem = (await get(child(dbRef, `public/items/${item.uid}`))).val();
            items.push({
                ...publicItem,
                ...item
            });
        }
    });
    return items;
});

export const createInventoryList = createAsyncThunk("createInventoryList", async (_, {getState}) => {
  const state = getState();
  const {items} = state.inventory;
  let newItems = await Promise.all(items.map(async item => {
    if (typeof item.diff === 'number') {
      await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${item.uid}`), {
        quantity: item.realQuantity
      });
    }
    return {
      uid: item.uid,
      restaurantItemsCategory: item.restaurantItemsCategory || "",
      restaurantGroup: item.restaurantGroup || "",
      code: item.code || "",
      name: item.name || "",
      unit: item.unit || "",
      quantity: item.quantity || "",
      realQuantity: item.realQuantity || "",
      diff: item.diff || "",
      vat: item.vat || ""
    }
  }));
  return await setDoc(doc(DB, "inventory", "users", AUTH.currentUser.uid, moment(new Date()).format("X")),
      {items: newItems, operator: getSessionOperator() || ""},
      {merge: true});
});

export const fetchAllInventoryHistories = createAsyncThunk("fetchAllInventoryHistories", async () => {
    let arr = [];
    const q = query(collection(DB, `inventory/users/${AUTH.currentUser.uid}`));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
        arr.push({
            ...doc.data(),
            uid: doc.id
        });
    });
    return arr;
});

const initialState = {
    items: [],
    inventoriesHistory: [],
    loading: false
};

const slice = createSlice({
    name: "inventory",
    initialState,
    reducers: {
        setWarehouseItems: (state, {payload}) => {
            state.items = payload;
        },
        changeWarehouseItemReelQuantity: (state, {payload}) => {
            const realQty = Number(Number(payload.quantity).toFixed(3));
            let arr = [...current(state.items)];
            let index = arr.findIndex(item => item.uid === payload.itemId);
            if (index !== -1) {
                const itemQty = arr[index].quantity ? Number(Number(arr[index].quantity).toFixed(2)) : 0;
                arr[index] = {
                    ...arr[index],
                    realQuantity: realQty,
                    diff:Number(Number(realQty - itemQty).toFixed(3))
                };
            }
            state.items = arr;
        }
    },
    extraReducers: {
        [fetchAllInventoryItems.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllInventoryItems.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.items = payload;
        },
        [fetchAllInventoryItems.rejected]: (state) => {
            state.loading = false;
        },
        // TODO PROVEITI DA NE TREBA MOZDA DA BUDE getSessionOperator().uid? DENI
        [createInventoryList.fulfilled]: (state, {meta}) => {
            const {requestId} = meta;
            let arr = [...current(state.inventoriesHistory)];
            arr.push({
                uid: requestId,
                items: [...current(state.items)],
                operator: getSessionOperator()
            });
            state.inventoriesHistory = arr;
        },
        [fetchAllInventoryHistories.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllInventoryHistories.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.inventoriesHistory = payload;
        },
        [fetchAllInventoryHistories.rejected]: (state) => {
            state.loading = false;
        },
    }
});
export const {changeWarehouseItemReelQuantity, setWarehouseItems} = slice.actions;
export default slice.reducer;