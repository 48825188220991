import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @mui
import { Box, Button, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
// assets
import { UploadIllustration } from "../../assets/illustratuions";
//
//
import RejectionFiles from "./errors/RejectionFiles";
import MultiFilePreview from "./preview/MultiFilePreview";
import SingleFilePreview from "./preview/SingleFilePreview";


// ----------------------------------------------------------------------

const StyledDropZone = styled("div")(({ theme }) => ({
  outline: "none",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  "&:hover": {
    opacity: 0.72
  }
}));

// ----------------------------------------------------------------------

Upload.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  files: PropTypes.array,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  thumbnail: PropTypes.bool,
  helperText: PropTypes.node,
  onRemoveAll: PropTypes.func
};

export default function Upload({
                                 disabled,
                                 multiple = false,
                                 error,
                                 helperText,
                                 //
                                 file,
                                 onDelete,
                                 //
                                 files,
                                 thumbnail,
                                 onUpload,
                                 onRemove,
                                 onRemoveAll,
                                 sx,
                                 ...other
                               }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other
  });

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, position: "relative", ...sx }}>
      {files.length < 3 && <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72
          }),
          ...(isError && {
            color: "error.main",
            bgcolor: "error.lighter",
            borderColor: "error.light"
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: "none"
          }),
          ...(hasFile && {
            padding: "12% 0"
          })
        }}
      >
        <input {...getInputProps()} />

        <Placeholder
          sx={{
            ...(hasFile && {
              opacity: 0
            })
          }}
        />

        {hasFile && <SingleFilePreview file={file} />}
      </StyledDropZone>}

      <RejectionFiles fileRejections={fileRejections} />


      {!hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview files={files} thumbnail={thumbnail} onRemove={onRemove} />
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
                Ukloni sve
              </Button>
            )}

            {onUpload && (
              <Button size="small" variant="contained" onClick={onUpload}>
                Okači priloge
              </Button>
            )}
          </Stack>
        </>
      )}

      {helperText && helperText}
    </Box>
  );
}

// ----------------------------------------------------------------------

Placeholder.propTypes = {
  sx: PropTypes.object
};

function Placeholder({ sx, ...other }) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: "column",
        md: "row"
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: "center",
          md: "left"
        },
        ...sx
      }}
      {...other}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          Prevuci ili izaberi prilog
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Prevucite ili kliknite
          <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 0.5,
              color: "primary.main",
              textDecoration: "underline"
            }}
          >
            ovde
          </Typography>
          kako bi ste dodali PDF prilog. (Maksimalan broj priloga je 3 i veličina je ograničena na 25 MB).
        </Typography>
      </Box>
    </Stack>
  );
}
