import {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle, MenuItem, Select} from "@mui/material";
import {useLocales} from "../../locales";

export default function LocationDialog({locations, loading, open, error, handleLocationClick, ...other}) {
    const [selectedLocation, setSelectedLocation] = useState({});
    const {translate} = useLocales();
    const handleChange = (event) => {
        setSelectedLocation(event.target.value);
    };
    return (
        <Dialog fullWidth maxWidth="xs" open={open} {...other}>
            <DialogTitle sx={{pb: 2}}>{translate("chooseLocation")}</DialogTitle>
            {loading ? <CircularProgress color="success"/> :
                <Select sx={{ml: 2, mr: 2}}
                        size="small"
                        value={selectedLocation}
                        onChange={handleChange}>
                    {locations.map((item) => (
                        <MenuItem
                            key={item.uid}
                            value={item}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2"
                            }}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>}
            <DialogActions>
                <Button variant="outlined" color="success" onClick={(e) => {
                    handleLocationClick(e, selectedLocation);
                }}>
                  {translate("accept")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
