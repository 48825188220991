import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useReducer } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";
import 'firebase/analytics';

import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref as sRef } from "firebase/storage";

// config
import { FIREBASE_API } from "../config";
import localStorage from "redux-persist/es/storage";
import { getUserInfo } from "../redux/slices/user";
import { clearSession } from "../helper/session";

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const analyticsFB = getAnalytics();

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const RDB = getDatabase(firebaseApp);

export const dbRef = ref(RDB);

export const storageRef = getStorage(firebaseApp);
export const usersImageStorage = sRef(storageRef, "public/userphotos");
export const productsImageStorage = sRef(storageRef, "public/itemphotos");

AuthProvider.propTypes = {
  children: PropTypes.node
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, "users", user.uid);

          const docSnap = await getDoc(userRef);

          const profile = docSnap.data();

          await getUserInfo(user);

          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                ...profile,
                role: "admin"
              }
            }
          });
        } else {

          localStorage.removeItem("userInfo");

          dispatch({
            type: "INITIAL",
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  // LOGOUT
  const logout = async () => {
    clearSession(true);
    await signOut(AUTH);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
