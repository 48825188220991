import uuidv4 from "../../utils/uuidv4";

const THEME_PATH = "esir--kelner-theme";
const COMPUTER_INDENTITY_PATH = "esir-uid-computer";
const SESSION_ID_PATH = "esir-session_id";
const TRAINING_MODE_PATH = "esir-kelner-training";
const OPERATOR_PATH = "esir-kelner-operator";
const SELECTED_LOCATION_PATH = "esir-kelner-selected-location";
const APP_VERSION_PATH = "esir-kelner-app-version";
const VIRTUAL_KEYBOARD = "esir-kelner-virtual-keyboard";
const LOGIN_POLICY = "esir-kelner-login-policy";
const SESSION_LANGUAGE = "kelner-v2-language";
const LPFR_URL_PATH = "lpfr_path";

// ----------- LPFR URL -----------------
export const setLPFRPathSession = (apiKey) => {
  localStorage.setItem(LPFR_URL_PATH, apiKey);
};

export const getLPFRPathSession = () => {
  return localStorage.getItem(LPFR_URL_PATH);
};

// ----------- LOKACIJA -------------------
export function setSessionSelectedLocation(location) {
  localStorage.setItem(SELECTED_LOCATION_PATH, JSON.stringify(location));
}

export function getSessionSelectedLocation() {
  return localStorage.getItem(SELECTED_LOCATION_PATH)
    ? JSON.parse(localStorage.getItem(SELECTED_LOCATION_PATH))
    : undefined;
}

// ----------- POLITIKA LOGOVNJA -------------------
export function setSessionLoginPolicy(isPinRequired) {
  localStorage.setItem(LOGIN_POLICY, JSON.stringify(isPinRequired));
}

export function getSessionLoginPolicy() {
  return localStorage.getItem(LOGIN_POLICY)
      ? JSON.parse(localStorage.getItem(LOGIN_POLICY))
      : undefined;
}

// ----------- OPERATER -------------------

export function setSessionOperator(operator) {
  localStorage.setItem(OPERATOR_PATH, JSON.stringify(operator));
}

export function removeSessionOperator() {
  localStorage.removeItem(OPERATOR_PATH);
}

export function getSessionOperator() {
  return localStorage.getItem(OPERATOR_PATH)
    ? JSON.parse(localStorage.getItem(OPERATOR_PATH))
    : undefined;
}

// ----------- VERZIJA -------------------
export function getSessionAppVersion() {
  return localStorage.getItem(APP_VERSION_PATH)
    ? parseFloat(localStorage.getItem(APP_VERSION_PATH))
    : null;
}

export function setSessionAppVersion(version) {
  localStorage.setItem(APP_VERSION_PATH, version.toString());
}

// ----------- JEZIK -------------------
export const getSessionLanguage = () => {
  return localStorage.getItem(SESSION_LANGUAGE);
};

export const setSessionLanguage = (language) => {
  return localStorage.setItem(SESSION_LANGUAGE, language);
};


// ----------- SESIJA -------------------
export function getAndCreateUniqueComputerUid() {
  let id = uuidv4();
  let storedSession = localStorage.getItem(COMPUTER_INDENTITY_PATH);
  if (!storedSession) {
    localStorage.setItem(COMPUTER_INDENTITY_PATH, id);
    return id;
  }
  return storedSession;
}

export function setSessionId(sessionId) {
  localStorage.setItem(SESSION_ID_PATH, sessionId);
}

export function getSessionId() {
  return localStorage.getItem(SESSION_ID_PATH);
}

export function deleteLocalSessionId() {
  localStorage.removeItem(SESSION_ID_PATH);
}

// ----------- OBUKA MOD -------------------
export function setSessionTrainingMode(active) {
  localStorage.setItem(TRAINING_MODE_PATH, active);
}

export function getSessionTrainingMode() {
  return localStorage.getItem(TRAINING_MODE_PATH) ? (localStorage.getItem(TRAINING_MODE_PATH) === "true") : false;
}

// ----------- TEMA -------------------
export function setSessionTheme(theme) {
  localStorage.setItem(THEME_PATH, theme);
}

export function getSessionTheme() {
  let theme = localStorage.getItem(THEME_PATH);
  return theme ? theme : "light";
}


// ----------- VIRTUALNA TASTATURA ---------------

export function setSessionVirtualKeyboardOn(active) {
  return localStorage.setItem(VIRTUAL_KEYBOARD, active)
}

export function isVirtualKeyboardOn() {
  return localStorage.getItem(VIRTUAL_KEYBOARD) ?
    localStorage.getItem(VIRTUAL_KEYBOARD) === "true" :
    false
}
// ----------- OSTALO -------------------
export function clearSession(isLogout = false) {
  let itemsToRemove;
  if (isLogout) {
    itemsToRemove = [SELECTED_LOCATION_PATH, TRAINING_MODE_PATH, OPERATOR_PATH];
  } else {
    itemsToRemove = [TRAINING_MODE_PATH, OPERATOR_PATH];
  }
  itemsToRemove.forEach(item => localStorage.removeItem(item));
}
