import {useEffect, useLayoutEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
// @mui
import {Box} from "@mui/material";
// hooks
// components
//
import Main from "./Main";
import Header from "./header";
import NavVertical from "./nav/NavVertical";
import {syncAllInvoices} from "../../redux/slices/synchronization";
import {dispatch, useSelector} from "../../redux/store";
import {useSnackbar} from "notistack";
import {fetchAllUserLicense, updateIsExpired} from "../../redux/slices/license";
import {ALL_LICENSE, ONE_DAY_SECONDS_TIMEOUT, ONE_MINUTE_SECONDS_TIMEOUT, OPERATORS_GROUP} from "../../constants";
import moment from "moment/moment";
import {dbRef} from "../../auth/FirebaseContext";
import {getSessionAppVersion, getSessionOperator, getSessionSelectedLocation} from "../../helper/session";
import {child, get} from "firebase/database";
import LicenseExpiredDialog from "../../components/licenseExpiredDialog";
import CacheDialog from "../../components/cacheDialog";
import {addRestaurantListener} from "../../helper/firestore/restaurant/listener";
import LoadingModal from "../../components/loading-modal";
import {useLoading} from "../../context/Loading";
import {fetchAllLocations} from "../../redux/slices/locations";
import LocationDialog from "../../components/LocationDialog";
import {PATH_DASHBOARD} from "../../routes/path";
import {fetchSettings} from "../../redux/slices/settings";
import {fetchTaxCoreStatus} from "../../redux/slices/taxCore";
import {useLocationContext} from "../../context/Location";
import {useLocales} from "../../locales";

import { getAuth, onIdTokenChanged } from 'firebase/auth';
// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const {loading} = useLoading();
    const { translate } = useLocales();
    const {setLocation} = useLocationContext();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const operator = getSessionOperator().group;
    const {settings} = useSelector(state => state.settings);
    const {isWebLicenseExpired} = useSelector(((state) => state.license));
    const {locations, loading: loadingLocation, error} = useSelector((select => select.locations));

    const [open, setOpen] = useState(false);
    const [openVersionConfirm, setOpenVersionConfirm] = useState(false);
    const [openLocationDialog, setOpenLocationDialog] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const auth = getAuth();
        let refreshTimeout;
        const refreshIdToken = async () => {
            const user = auth.currentUser;
            if (user) {
                await user.getIdToken(true);
                scheduleTokenRefresh();
            }
        };

        const scheduleTokenRefresh = () => {
            const user = auth.currentUser;
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    const expirationTimeGMT = moment.utc(idTokenResult.expirationTime);

                    const currentTimeGMT = moment.utc();

                    const refreshTime = expirationTimeGMT.subtract(5, 'minutes');

                    const delay = refreshTime.diff(currentTimeGMT);

                    if (delay > 0) {
                        clearTimeout(refreshTimeout);
                        refreshTimeout = setTimeout(refreshIdToken, delay);
                    } else {
                        console.log('No refresh scheduled, delay is less than 0');
                    }
                });
            }
        };


        const unsubscribe = onIdTokenChanged(auth, (user) => {
            if (user) {
                scheduleTokenRefresh();
            }
        });

        return () => {
            clearTimeout(refreshTimeout);
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!settings) {
            dispatch(fetchSettings());
        }
        dispatch(fetchTaxCoreStatus())
    }, [settings]);

    useEffect(() => {
        dispatch(fetchAllLocations()).unwrap().then(response => {
            if (response.length === 1) {
              setLocation(response[0]);
            } else {
                if (getSessionSelectedLocation() === undefined) {
                    setOpenLocationDialog(true);
                } else {
                    let selected = response.some(location => location.PAC === getSessionSelectedLocation().PAC);
                    if (!selected) {
                        setOpenLocationDialog(true);
                    } else {
                        setLocation(getSessionSelectedLocation())
                    }
                }
            }
        }).catch(reason => {
            console.error("fetchAllLocations", reason);
            enqueueSnackbar(translate("cannotLoadLocations"), {variant: "error"});
        });
    }, [enqueueSnackbar]);

    useEffect(() => {
        addRestaurantListener(location.pathname === PATH_DASHBOARD.general.app && ![OPERATORS_GROUP.admin, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager].includes(operator));
    }, [location.pathname, operator]);

    useLayoutEffect(() => {
        dispatch(syncAllInvoices()).unwrap().catch(reason => {
            console.error("syncAllInvoices", reason);
            enqueueSnackbar(translate("invoiceSyncError"), {variant: "error"});
        });
    }, [enqueueSnackbar]);

    const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose}/>;

    const onLocationChosen = (e, location) => {
      setLocation(location);
        setOpenLocationDialog(false);
        enqueueSnackbar(translate("locationSuccessAdded"), {variant: "success"});
    };

    useEffect(() => {
        const sessionVersion = getSessionAppVersion();
        get(child(dbRef, `public/webAppData/version`)).then(value => {
            if (sessionVersion !== null && sessionVersion !== value.val()) {
                setOpenVersionConfirm(true);
            }
        });
    }, []);

    useEffect(() => {
        dispatch(fetchAllUserLicense()).unwrap().then(response => {
            let webLicense = response.filter(license => license.productID === ALL_LICENSE.restaurant);
            let isWebLicenseExpired = moment(webLicense[0].expiredDate).isBefore(moment.now());
            if (!isWebLicenseExpired) {
                let timeTrigger = dateDiffInMinutes(webLicense[0].expiredDate);
                if (ONE_DAY_SECONDS_TIMEOUT > timeTrigger * ONE_MINUTE_SECONDS_TIMEOUT) {
                    const timer = setTimeout(() => {
                        dispatch(updateIsExpired());

                    }, timeTrigger * ONE_MINUTE_SECONDS_TIMEOUT);
                    return () => clearTimeout(timer);
                }
            }
        });
    }, [isWebLicenseExpired]);

    function dateDiffInMinutes(licenceTime) {
        let a = moment(new Date());
        let b = moment(licenceTime);
        return b.diff(a, "minutes");
    }

    return (
        <>
            {loading && <LoadingModal/>}
            <Header onOpenNav={handleOpen}/>

            <Box
                sx={{
                    display: {lg: "flex"},
                    minHeight: {lg: 1}
                }}>
                {renderNavVertical}

                <Main>
                    <Outlet/>
                </Main>
            </Box>

            {openLocationDialog && <LocationDialog
                locations={locations}
                loading={loadingLocation}
                open={openLocationDialog}
                error={error}
                handleLocationClick={onLocationChosen}/>}
            {isWebLicenseExpired && <LicenseExpiredDialog open={isWebLicenseExpired}/>}
            {openVersionConfirm && <CacheDialog open={openVersionConfirm} setOpen={setOpenVersionConfirm}/>}</>
    );
}
