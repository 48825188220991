// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    operatorLogin: path(ROOTS_AUTH, '/operator-login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/home'),
        analytic: path(ROOTS_DASHBOARD, '/analytic'),
        createOrder: path(ROOTS_DASHBOARD, '/create-order'),
        settings: path(ROOTS_DASHBOARD, '/settings'),
    },
    compensation: {
        root: path(ROOTS_DASHBOARD, "/compensation"),
        list: path(ROOTS_DASHBOARD, "/compensation/list"),
        editRemove: path(ROOTS_DASHBOARD, "/compensation/edit"),
    },
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        new: path(ROOTS_DASHBOARD, '/user/new'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        cards: path(ROOTS_DASHBOARD, '/user/cards'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
        edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    },
    reports: {
        root: path(ROOTS_DASHBOARD, '/reports'),
        overview: path(ROOTS_DASHBOARD, '/reports/overview'),
        item: path(ROOTS_DASHBOARD, '/reports/item'),
        costs: path(ROOTS_DASHBOARD, '/reports/costs'),
        writeOff: path(ROOTS_DASHBOARD, '/reports/writeOff'),
        dpu: path(ROOTS_DASHBOARD, '/reports/dpu'),
        activeOrders: path(ROOTS_DASHBOARD, '/reports/activeOrders'),
        filter: path(ROOTS_DASHBOARD, '/reports/filter'),
        table: path(ROOTS_DASHBOARD, '/reports/table'),
        turnoverWithoutCompensation: path(ROOTS_DASHBOARD, '/reports/turnoverWithoutCompensation'),
        profit: path(ROOTS_DASHBOARD, '/reports/profit'),
        itemCard: path(ROOTS_DASHBOARD, '/reports/item-card'),
        representations: path(ROOTS_DASHBOARD, '/reports/representations'),
        deletedOrders: path(ROOTS_DASHBOARD, '/reports/deletedOrders'),

    },
    database: {
        root: path(ROOTS_DASHBOARD, '/warehouse'),
        list: path(ROOTS_DASHBOARD, '/warehouse/list'),
        addWarehouse: path(ROOTS_DASHBOARD, '/warehouse-add'),
        warehouseLagerItems: path(ROOTS_DASHBOARD, '/warehouse/lager-items')
    },
    warehouse: {
        root: path(ROOTS_DASHBOARD, '/warehouse'),
        suppliers: path(ROOTS_DASHBOARD, '/warehouse/suppliers'),
        newSupplier: path(ROOTS_DASHBOARD, '/warehouse/suppliers/new'),
        editSupplier: path(ROOTS_DASHBOARD, '/warehouse/suppliers/edit'),
        factures: path(ROOTS_DASHBOARD, '/warehouse/factures'),
        newFacture: path(ROOTS_DASHBOARD, '/warehouse/factures/new'),
        editFacture: path(ROOTS_DASHBOARD, '/warehouse/factures/edit'),
        viewFacture: path(ROOTS_DASHBOARD, '/warehouse/factures/view'),
        addPaidAmountFacture: path(ROOTS_DASHBOARD, '/warehouse/factures/addPaidAmountFacture'),
        stockList: path(ROOTS_DASHBOARD, '/warehouse/stock-list'),
        ingredients: path(ROOTS_DASHBOARD, '/warehouse/ingredients'),
        editIngredient: path(ROOTS_DASHBOARD, '/warehouse/ingredients/edit'),
        createIngredient: path(ROOTS_DASHBOARD, '/warehouse/ingredients/create'),
        inventory: path(ROOTS_DASHBOARD, '/warehouse/inventory'),
        inventoryPreview: path(ROOTS_DASHBOARD, '/warehouse/inventory/preview'),
        writeOff: path(ROOTS_DASHBOARD, '/warehouse/writeOff'),
        newWriteOff: path(ROOTS_DASHBOARD, "/warehouse/writeOff/new"),
        clients: path(ROOTS_DASHBOARD, '/warehouse/clients'),
        clientsPreview: path(ROOTS_DASHBOARD, '/warehouse/clients/preview'),
    },
    items: {
        root: path(ROOTS_DASHBOARD, '/items'),
        list: path(ROOTS_DASHBOARD, '/items/list'),
        new: path(ROOTS_DASHBOARD, '/items/new'),
        starters: path(ROOTS_DASHBOARD, '/items/starters'),
        edit: (id) => path(ROOTS_DASHBOARD, `/items/${id}/edit`),
    },
    discounts: {
        root: path(ROOTS_DASHBOARD, '/discounts'),
        list: path(ROOTS_DASHBOARD, '/discounts/list'),
        new: path(ROOTS_DASHBOARD, '/discounts/new'),
        customers: path(ROOTS_DASHBOARD, '/discounts/customers'),
        customersNew: path(ROOTS_DASHBOARD, '/discounts/customersNew'),
        edit: (id) => path(ROOTS_DASHBOARD, `/discounts/${id.uid}/edit`),
        editCustomer: (id) => path(ROOTS_DASHBOARD, `/discounts/${id.uid}/editCustomer`),
    },
    operators: {
        root: path(ROOTS_DASHBOARD, '/operators'),
        list: path(ROOTS_DASHBOARD, '/operators/list'),
        new: path(ROOTS_DASHBOARD, '/operators/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/operators/${id.uid}/edit`),
    },
    customers: {
        root: path(ROOTS_DASHBOARD, '/customers'),
        list: path(ROOTS_DASHBOARD, '/customers/list'),
        new: path(ROOTS_DASHBOARD, '/customers/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/customers/${id.uid}/edit`),
    },
    costs: {
        root: path(ROOTS_DASHBOARD, '/costs'),
        list: path(ROOTS_DASHBOARD, '/costs/list'),
        new: path(ROOTS_DASHBOARD, '/costs/new'),
        edit: (id) => path(ROOTS_DASHBOARD, `/costs/${id.uid}/edit`),
    },
    invoices: {
        root: path(ROOTS_DASHBOARD, "/invoices"),
        list: path(ROOTS_DASHBOARD, "/invoices/list")
    }
};

