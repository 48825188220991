import { Link as RouterLink } from "react-router-dom";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// auth
// routes
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { PATH_DASHBOARD } from "../../../routes/path";
import { getSessionOperator } from "../../../helper/session";
import { findRoleName } from "../../../helper/other";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12)
}));

// ----------------------------------------------------------------------

export default function NavAccount() {

  const operator = getSessionOperator();

  return (
    <Link to={PATH_DASHBOARD.general.app} component={RouterLink} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar src={operator?.photoURL} alt={operator.username} name={operator.username} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {operator.username}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {findRoleName(operator.group)}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}