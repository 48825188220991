import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {child, get, push, update} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";

export const fetchAllDiscountsCustomers = createAsyncThunk('fetchAllDiscountsCustomers', async () => {
    let arr = [];
    try {
        (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers`))).forEach(child1 => {
            arr.push({
                ...child1.val(),
                uid: child1.key,
            })
        });
    } catch (e) {
    }
    return arr;
})

export const updateDiscountsCustomer = createAsyncThunk("updateDiscountsCustomer", async (payload) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers/${payload.uid}`), payload);
    return payload
})

export const addDiscountCustomer = createAsyncThunk("addDiscountCustomer", async (payload) => {
    let obj = {...payload}
    await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers`), payload).then(async response => {
        obj.uid = response.key
    }).catch(e => {
    })
    return obj
})

export const getDiscountCostumer = createAsyncThunk('getDiscountCostumer', async (tin) => {

})

export const changeDiscountCustomerStatus = createAsyncThunk("updateDiscountCustomerStatus", async (data) => {
    (await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/discountCustomers/${data.uid}`), {
        ...data,
        inactive: !data.inactive
    }));
    return {
        ...data,
        inactive: !data.inactive
    };
})

const initialState = {
    discountCustomersList: [],
    loading: false,
    discountCustomer: undefined
}


export const discountCustomerSlice = createSlice({
    name: 'discountCustomer',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllDiscountsCustomers.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllDiscountsCustomers.fulfilled]: (state, {payload}) => {
            state.discountCustomersList = payload;
            state.loading = false;
        },
        [fetchAllDiscountsCustomers.rejected]: (state) => {
            state.loading = false;
        },
        [updateDiscountsCustomer.pending]: (state) => {
            state.loading = true;
        },
        [updateDiscountsCustomer.fulfilled]: (state, {payload}) => {
            let arr = [...current(state.discountCustomersList)];
            let index = arr.findIndex(item => item.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.discountCustomersList = arr
            }
            state.loading = false;
        },
        [updateDiscountsCustomer.rejected]: (state) => {
            state.loading = false;
        },
        [addDiscountCustomer.pending]: (state) => {
            state.loading = true;
        },
        [addDiscountCustomer.fulfilled]: (state, {payload}) => {
            let arr = [...current(state.discountCustomersList)];
            arr.push(payload)
            state.discountCustomersList = arr
            state.loading = false;
        },
        [addDiscountCustomer.rejected]: (state) => {
            state.loading = false;
        },
        [changeDiscountCustomerStatus.pending]: (state) => {
            state.loading = true;
        },
        [changeDiscountCustomerStatus.fulfilled]: (state, {payload}) => {
            let arr = [...current(state.discountCustomersList)];
            let index = arr.findIndex(item => item.uid === payload.uid)
            arr[index] = payload
            state.discountCustomersList = arr
            state.loading = false;
        },
        [changeDiscountCustomerStatus.rejected]: (state) => {
            state.loading = false;
        },
        //getClientDetails
        [getDiscountCostumer.pending]: (state) => {
            state.loadingInfo = true;
        },
        [getDiscountCostumer.fulfilled]: (state, {payload}) => {
            state.discountCustomersList = payload
            state.loadingInfo = false;
        },
        [getDiscountCostumer.rejected]: (state) => {
            state.loadingInfo = false;
        },
    }
});

export const discountCustomerReducer = discountCustomerSlice.reducer
