// @mui
import {Alert, Box, Stack, Tooltip, Typography} from '@mui/material';
// hooks
import {useAuthContext} from '../../../auth/useAuthContext';
// layouts
import LoginLayout from '../../../layouts/login';
import AuthLoginForm from "./AuthLoginForm";
import {useLocales} from "../../../locales";
import { useState } from "react";
import packageInfo from "../../../../package.json"
// ----------------------------------------------------------------------

//  TODO BROJ TELEFONA
export default function LoginAuth0() {
    const {method} = useAuthContext();
    const {translate} = useLocales();

    
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

    return (
        <LoginLayout>
            <Stack spacing={2} sx={{mb: 5, position: 'relative'}}>
                <Typography variant="h4">{translate("signIn")?.toUpperCase()}</Typography>

                <Tooltip title={method} placement="left">
                    <Box
                        component="img"
                        alt={method}
                        src={`/assets/icons/auth/ic_${method}.png`}
                        sx={{width: 32, height: 32, position: 'absolute', right: 0}}
                    />
                </Tooltip>
            </Stack>

            <Alert severity="info" sx={{mb: 3}}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={14} variant="caption">
                        {translate("tehnicalSupport")}:<strong>{` 24/7/365`}</strong>
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={14} variant="caption">
                        {translate("phoneNumber")}: <strong>+38751981295</strong>
                    </Typography>
                </Stack>
            </Alert>

            <AuthLoginForm/>

            <div
        style={
          { position: 'fixed'
          , bottom: 0
          , left: 0
          , padding: '10px'
          , cursor: 'pointer'
          }} onClick={toggleVisibility}>
        <Typography variant="caption" color="gray" visibility={ isVisible ? 'visible' : 'hidden'}>
          {`Version ${packageInfo.version}.${packageInfo.build}`}
        </Typography>
      </div>

        </LoginLayout>
    );
}
