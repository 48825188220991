import React, { createContext, useContext, useEffect, useState } from "react";

const WaiterSessionContext = createContext(null);

export const WaiterSessionProvider = ({children}) => {
    const [blockTimer, setBlockTimer] = useState(true);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!blockTimer) {
                if (seconds > 0) {
                    setSeconds((prevSeconds) => {
                        if (prevSeconds - 1 <= 0) {
                            disableTimer();
                        }
                        return prevSeconds - 1
                    });
                }
            }
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockTimer]);

    const enableTimer = () => {
        if (blockTimer) {
            setBlockTimer(false);
        }
    }

    const disableTimer = () => {
        if (!blockTimer) {
            setBlockTimer(true)
        }
    }

    const resetSession = () => {
        setSeconds(5);
    }

    return (
        <WaiterSessionContext.Provider value={{blockTimer, seconds, enableTimer, disableTimer, resetSession}}>
            {children}
        </WaiterSessionContext.Provider>
    );
};


function useWaiterSession() {
    return useContext(WaiterSessionContext);
}

export {WaiterSessionContext, useWaiterSession};