import { useState } from "react";
import { StyledCard } from "../../../components/settings/styles";
import Iconify from "../../../components/Iconify";
//

// ----------------------------------------------------------------------

export default function FullScreenOptions() {
    const [fullscreen, setFullscreen] = useState(false);

    const onToggleFullScreen = async () => {
      if (!document.fullscreenElement) {
        await document.documentElement.requestFullscreen();
        setFullscreen(true);
      } else if (document.exitFullscreen) {
        await document.exitFullscreen();
        setFullscreen(false);
      }
    };

    return (
        <StyledCard
            selected={fullscreen}
            onClick={onToggleFullScreen}
            sx={{
                height: 48,
                typography: 'subtitle2',
                '& .svg-color': {
                    ml: 1,
                    width: 16,
                    height: 16,
                },
            }}>
            {fullscreen ? 'Minimizuj' : 'Ceo ekran'}

            <Iconify
                icon={`${fullscreen ? 'mdi:fullscreen-exit' : 'ant-design:fullscreen-outlined'}`}
            />
        </StyledCard>
    );
}
