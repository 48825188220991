import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {child, get} from "firebase/database";
import {dbRef} from "../../auth/FirebaseContext";
import {clearCache} from "../../helper/other";
import {useLocales} from "../../locales";


export default function CacheDialog({open, setOpen}) {
    const {translate} = useLocales();

    const [version, setVersion] = useState();

    useEffect(() => {
        get(child(dbRef, `public/webAppData/version`)).then(value => {
            setVersion(value.val())
        })
    }, [])

    const handleClose = async () => {
        await clearCache(version)
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{mb: '5px'}} id="alert-dialog-title">
                    {translate("notification")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate("newVersionIsAvailable")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{mt: '-25px'}} onClick={handleClose} autoFocus>
                        {translate("ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
