import moment from "moment/moment";
import { putInvoiceOffline, putLogToFirebase } from "../../store/offlineDb";
import { getSessionSelectedLocation } from "../session";
import { customAxios } from "../../api/axios";
import { doc, setDoc } from "firebase/firestore";
import { AUTH, DB } from "../../auth/FirebaseContext";
import { loginOnError403 } from "../../api/vpfr";
import { postInvoiceLpfr } from "../../api/lpfr";
import {translate} from "../../locales/i18n";
import {INVOICE_TYPE} from "../../constants";
import {TRANSACTION_TYPE} from "../firestore";

export async function insertNewInvoice(data) {
  return new Promise(async (resolve, reject) => {
    await insertInvoice(data).then(async invoice => {
      await insertIntoFirestore(data, invoice);
      return resolve(invoice);
    }).catch(reason => {
      return reject(reason);
    });
  });
}

export function insertInvoice(data, isAlreadyPosted = false) {
  return new Promise(async (resolve, reject) => {
    await postInvoiceVpfr(data).then(response => {
      resolve(response.data);
    }).catch(async e => {
      parseInsertInvoiceError(e, reject);
      if (!isAlreadyPosted) {
        await loginOnError403(e).then(_ => {
          insertInvoice(data, true).then(r => {
            resolve(r);
          });
        });
      } else {
        await postInvoiceLpfr(data).then(response => {
          resolve(response.data);
        }).catch(reason => {
          reject(reason);
        });
      }
    });
  });
}

export function postInvoiceVpfr(data) {
  const token = AUTH.currentUser.stsTokenManager.accessToken;
  const pac = getSessionSelectedLocation().PAC;
  return customAxios(token, pac).post("/api/v3/invoices", data);
}


export async function insertIntoFirestore(data, response) {
  const timestamp = getTimestampFromSdc(response.sdcDateTime);
  if (typeof data === "string") {
    data = JSON.parse(data);
  }
  try {
    const invoiceType = convertInvoiceType(data.invoiceType);
    const transactionType = convertTransactionType(data.transactionType);
    const paymentMethod = convertPaymentMethod(data.payment);
    const itemsFromData = getItemsFromData(data.items);
    let obj = {
      dateAndTimeOfIssue: data.dateAndTimeOfIssue || null,
      buyerCostCenter: data.buyerCostCenter || null,
      buyerTin: data.buyerId || null,
      cashier: data.cashier,
      invoiceNumber: response.invoiceNumber,
      invoiceType: invoiceType,
      items: itemsFromData,
      paymentMethod: paymentMethod,
      referentDocumentDT: data.referentDocumentDT || null,
      referentDocumentNumber: data.referentDocumentNumber || null,
      sdcDateTime: response.sdcDateTime,
      taxItems: response.taxItems,
      totalAmount: response.totalAmount,
      transactionType: transactionType,
      internalData: {
        ...data.internalData,
        client: "web"
      }
    };
    await   putInvoiceOffline({
      ...obj,
      isInsertedOffline: true,
      invoiceType: data.invoiceType,
      paymentMethod: data.payment,
      transactionType: data.transactionType
    });
    //INVOICE
    const colRef = doc(DB, "invoices", "users", `${AUTH.currentUser.uid}`, timestamp);
    await setDoc(colRef, obj, { merge: true }).then(_ => {
      console.debug("Uspesno sacuvan racun na firestoru");
    }).catch(reason => {
      console.error("reason", reason);
      putLogToFirebase("insertIntoFirestore", "vpfr.js", [data, response], reason?.toString());
    });
  } catch (e) {
    console.error("e", e);
    putLogToFirebase("insertIntoFirestore", "vpfr.js", [data, response], e?.toString());
  }
}

export function getTimestampFromSdc(sdcDateTime) {
  try {
    return moment(sdcDateTime).format("x");
  } catch (e) {
    return new Date().toString();
  }
}

function parseInsertInvoiceError(e, reject) {
  if (e?.response?.status === 400) {
    if (e?.response?.data) {
      reject(e.response.data);
    } else {
      reject(e);
    }
  }
}

export const convertInvoiceType = (data) => {
  switch (data) {
    case "Normal":
      return "0";
    case "Proforma":
      return "1";
    case "Copy":
      return "2";
    case "Training":
      return "3";
    case "Advance":
      return "4";
    default:
      return "-1";
  }
};
export const convertTransactionType = (data) => {
  switch (data) {
    case "Sale":
      return "0";
    case "Refund":
      return "1";
    default:
      return "-1";
  }
};

export const translatePaymentNumber = (name) => {
  switch (name.toString()) {
    case "1":
      return translate("cash");
    case "2":
      return translate("card");
    case "3":
      return translate("check");
    case "4":
      return translate("wireTransfer");
    case "5":
      return translate("voucher");
    case "6":
      return translate("mobileMoney");
    case "0":
      return translate("other");
    default:
      return;
  }
};

export function getCounterFromInvoice(invoice) {
  let invoiceNumber = invoice.invoiceNumber;
  let index = invoiceNumber.lastIndexOf("-") + 1;
  let counter = invoiceNumber.substring(index, invoiceNumber.length);
  let firstLetter;
  switch (invoice.invoiceType) {
    case INVOICE_TYPE.training:
      firstLetter = "О";
      break;
    case INVOICE_TYPE.copy:
      firstLetter = "К";
      break;
    case INVOICE_TYPE.normal:
      firstLetter = "П";
      break;
    case INVOICE_TYPE.advance:
      firstLetter = "А";
      break;
    case INVOICE_TYPE.proforma:
      firstLetter = "Р";
      break;
    default:
      firstLetter = "#";
      break;
  }
  let secondLetter = invoice.transactionType === TRANSACTION_TYPE.sale ? "П" : "Р";
  return `${counter}/${firstLetter}${secondLetter}`;
}

export const paymentTypes = [
  { key: "1", name: "cash" },
  { key: "6", name: "mobileMoney" },
  { key: "2", name: "card" },
  { key: "3", name: "check" },
  { key: "4", name: "wireTransfer" },
  { key: "5", name: "voucher" },
  { key: "0", name: "other" }
];


export const convertPaymentMethod = (data) => {
  return data.map(obj => {
    if (obj.paymentType === "Other") {
      return { ...obj, paymentType: "0" };
    } else if (obj.paymentType === "Cash") {
      return { ...obj, paymentType: "1" };
    } else if (obj.paymentType === "Card") {
      return { ...obj, paymentType: "2" };
    } else if (obj.paymentType === "Check") {
      return { ...obj, paymentType: "3" };
    } else if (obj.paymentType === "WireTransfer") {
      return { ...obj, paymentType: "4" };
    } else if (obj.paymentType === "Voucher") {
      return { ...obj, paymentType: "5" };
    } else if (obj.paymentType === "MobileMoney") {
      return { ...obj, paymentType: "6" };
    }
    return obj;
  });
};
const getItemsFromData = (data) => {
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let object = data[i];
    object.uid = data[i].uid || null;
    object.totalAmount = parseFloat(parseFloat(object.totalAmount).toFixed(2));
    object.unitPrice = parseFloat(parseFloat(object.unitPrice).toFixed(2));
    const newObj =  removeNullValues(object)
    arr.push(newObj);
  }
  return arr;
};

function removeNullValues(item) {
    for (const key in item) {
      if (item[key] === undefined) {
        item[key] = null;
      }
    }
    return item;
}

export function createAdvanceProductName(vat) {
  if (!vat) {
    return undefined;
  }
  let name;
  switch (vat.toString()) {
    case "F":
      name = "10: Avans (F)";
      break;
    case "E":
      name = "11: Avans (E)";
      break;
    case "T":
      name = "12: Avans (T)";
      break;
    case "A":
      name = "13: Avans (A)";
      break;
    case "Ђ":
      name = "10: Avans (Ђ)";
      break;
    case "Е":
      name = "11: Avans (Е)";
      break;
    case "Г":
      name = "12: Avans (Г)";
      break;
    case "А":
      name = "13: Avans (А)";
      break;
    default:
      name = undefined;
  }
  return name;
}
