import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {child, get} from "firebase/database";

export const fetchAllFbVats = createAsyncThunk('fetchAllFbVats', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/vats`))).forEach(child1 => {
        child1.val().taxRates.forEach(tax => {
            const obj = {}
            obj.label = tax.label
            obj.rate = tax.rate
            arr.push(obj);
        })
    });
    return arr;
})

const initialState = {
    fbVats: [],
    loading: true,
    error: undefined
}

export const vatSlice = createSlice({
    name: 'vats',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllFbVats.pending]: (state) => {
            state.loading = true;
            state.error = undefined;
            state.fbVats = [];
        },
        [fetchAllFbVats.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.error = undefined;
            state.fbVats = payload;
        },
        [fetchAllFbVats.rejected]: (state, _) => {
            state.loading = false;
            state.error = undefined;
            state.fbVats = [];
        },
    }
});

export const vatReducer = vatSlice.reducer