import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enLocales from "./langs/en";
import srLocales from "./langs/sr";
import deLocales from "./langs/de";
import ruLocales from "./langs/ru";
import baLocales from "./langs/ba";
import { getSessionLanguage } from "../helper/session";

// ----------------------------------------------------------------------

let lng = "ba";

const storageAvailable = getSessionLanguage();

if (storageAvailable) {
  lng = storageAvailable;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      sr: { translations: srLocales },
      de: { translations: deLocales },
      ru: { translations: ruLocales },
      ba: { translations: baLocales },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false
    }
  });

export const translate = (name, options) => {
  return i18n.t(name, options);
};

export default i18n;
