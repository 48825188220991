import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { insertAllUserInvoicesOffline, insertAllUserInvoicesOfflineForPeriod } from "../../store/offlineDb";

export const syncAllInvoices = createAsyncThunk("syncAllInvoices", async () => {
    return insertAllUserInvoicesOffline();
});

export const syncInvoiceInPeriod = createAsyncThunk("syncInvoiceInPeriod", async (period) => {
    return insertAllUserInvoicesOfflineForPeriod(period);
});

const initialState = {
    loading: false
};

export const slice = createSlice({
    name: "synchronizations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        const setLoading = (state, action) => {
            state.loading = action.type.endsWith("pending");
        };

        builder
            .addCase(syncAllInvoices.pending, setLoading)
            .addCase(syncAllInvoices.fulfilled, setLoading)
            .addCase(syncAllInvoices.rejected, setLoading)
            .addCase(syncInvoiceInPeriod.pending, setLoading)
            .addCase(syncInvoiceInPeriod.fulfilled, setLoading)
            .addCase(syncInvoiceInPeriod.rejected, setLoading);
    }
});

export default slice.reducer;
