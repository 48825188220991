import axios from "axios";
import {getLPFRPathSession, getSessionSelectedLocation} from "../helper/session";
import {LPFR_URL, TEST_LPFR_URL_BA} from "../config";

const getBaseUrl = () => {
  const location = getSessionSelectedLocation();
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    const saved = getLPFRPathSession();
    if (saved) {
      return saved;
    }
    return TEST_LPFR_URL_BA;
  }
  return LPFR_URL;
}

const customAxios = axios.create({
  baseURL: LPFR_URL,
  headers: {
    "Accept": "application/json"
  },
  withCredentials: false
});

export function getTaxRates() {
  const url = getBaseUrl();
  const location = getSessionSelectedLocation();
  let headers = {}
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    headers = {
      "Accept": "application/json",
      "Accept-Language": "sr-Cyrl-BA"
    }
  }
  return customAxios.get(`tax-rates`, {
    baseURL: url,
    headers: headers
  });
}

export function getStatusLpfr() {
  const url = getBaseUrl();
  const location = getSessionSelectedLocation();
  let headers = {}
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    headers = {
      "Accept": "application/json",
      "Accept-Language": "sr-Cyrl-BA"
    }
  }
  return customAxios.get("status", {
    baseURL: url,
    headers: headers
  });
}

export function postInvoiceLpfr(data) {
  const url = getBaseUrl();
  const location = getSessionSelectedLocation();
  let headers = {}
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    headers = {
      "Accept": "application/json",
      "Accept-Language": "sr-Cyrl-BA"
    }
  }
  const sendData = {...data, options: {...data.options, OmitQRCodeGen: 0}};
  return customAxios.post("invoices", sendData, {
    baseURL: url,
    headers: headers
  });
}

export function getInvoice(requestId) {
  const url = getBaseUrl();
  const location = getSessionSelectedLocation();
  let headers = {}
  if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
    headers = {
      "Accept": "application/json",
      "Accept-Language": "sr-Cyrl-BA"
    }
  }
  return customAxios.get(`invoices/${requestId}`, {
    baseURL: url,
    headers: headers
  });
}
