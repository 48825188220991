import {AUTH, DB} from "../auth/FirebaseContext";
import { getSessionSelectedLocation } from "../helper/session";
import axios from "axios";
import { getStatusLpfr } from "./lpfr";
import {getTimestampFromSdc, insertIntoFirestore, insertInvoice} from "../helper/invoice";
import {deleteDoc, doc, setDoc, updateDoc} from "firebase/firestore";
import {putLogToFirebase} from "../store/offlineDb";
import {TRANSACTION_TYPE} from "../constants";


const BASE_URL = "https://europe-central2-esir-44ade.cloudfunctions.net/taxCoreAPI11";

const customAxios = (token, pac) => axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
    "PAC": pac
  },
  withCredentials: false
});

export async function connectOpenAdvanceInvoice(invoice, response, rootInvoice, referentInvoiceTimestamp) {
  try {
    const timestamp = getTimestampFromSdc(response.sdcDateTime);
    let linked = [...(rootInvoice.linkedInvoices || [])];
    let newAmount = Number(rootInvoice.advanceAmount);
    linked.push(parseInt(timestamp));
    newAmount += Number(response.totalAmount);

    await updateDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`), {
      advanceAmount: Number(Number(newAmount).toFixed(2)),
      linkedInvoices: linked
    });
  } catch (e) {
    putLogToFirebase("connectOpenAdvanceInvoice",
        "helper/firestore/openAdvance",
        [invoice, response, rootInvoice, referentInvoiceTimestamp],
        e);
    throw new Error("7533");
  }
}

const convertItemsForOpenAdvice = (items) => {
  return items.map(item => ({
    uid: item.uid || null,
    vat: item.labels[0] || null,
    labels: item.labels || null,
    name: item.name || null,
    quantity: Number(item.quantity),
    totalAmount: Number(item.totalAmount),
    unit: item.unit || null,
    unitPrice: Number(item.unitPrice)
  }));
};
export async function createOpenAdvanceInvoice(invoice, response, realItems) {
  const timestamp = getTimestampFromSdc(response.sdcDateTime);
  const advanceAmount = Number(response.totalAmount);
  return await setDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${timestamp}`), {
    advanceAmount: Number(Number(advanceAmount).toFixed(2)),
    invoiceNumber: response.invoiceNumber,
    items: convertItemsForOpenAdvice(realItems),
    linkedInvoices: [parseInt(timestamp)],
    linkedRefund: [],
    timestamp: parseInt(timestamp)
  }, { merge: true });
}

export async function insertOrRefundAdvanceInvoice(data, realItems, rootInvoice, referentInvoiceTimestamp) {
  return new Promise(async (resolve, reject) => {
    await insertInvoice(data).then(async invoice => {
      await insertIntoFirestore(data, invoice);
      if (data.transactionType === TRANSACTION_TYPE.sale) {
        await createOpenAdvanceInvoice(data, invoice, realItems);
      } else {
        await refundOpenAdvanceInvoice(data, invoice, rootInvoice, referentInvoiceTimestamp);
      }
      return resolve(invoice);
    }).catch(reason => {
      return reject(reason);
    });
  });
}

export async function refundOpenAdvanceInvoice(invoice, response, rootInvoice, referentInvoiceTimestamp) {
  try {
    const timestamp = getTimestampFromSdc(response.sdcDateTime);
    let linked = [...(rootInvoice.linkedInvoices || [])];
    let refund = [...(rootInvoice.linkedRefund || [])];
    refund.push(parseInt(timestamp));
    let newAmount = Number(rootInvoice.advanceAmount);
    // refundacija prvog sto znaci potpuna refundacija (prebacuje se u advanceTransactionsHistory)
    if (parseInt(rootInvoice.timestamp) === parseInt(referentInvoiceTimestamp)) {
      refund = [...linked, ...refund];
      linked = [];
      newAmount -= Number(response.totalAmount);
      await setDoc(doc(DB, "invoices", `advanceTransactionsHistory`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`),
          {
            advanceAmount: Number(Number(newAmount).toFixed(2)),
            invoiceNumber: rootInvoice.invoiceNumber,
            items: rootInvoice.items,
            linkedInvoices: linked,
            linkedRefund: refund,
            timestamp: rootInvoice.timestamp
          }, { merge: true });
      await deleteDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`));
    } else {
      const indexLinked = linked.indexOf(parseInt(referentInvoiceTimestamp));
      linked.splice(indexLinked, 1);
      newAmount += Number(response.totalAmount);
      await updateDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid, `${rootInvoice.timestamp}`), {
        advanceAmount: Number(Number(newAmount).toFixed(2)),
        linkedInvoices: linked,
        linkedRefund: refund
      });
    }
  } catch (e) {
    putLogToFirebase("refundOpenAdvanceInvoice",
        "helper/firestore/openAdvance",
        [invoice, response, rootInvoice, referentInvoiceTimestamp],
        e);
    throw new Error("7533");
  }
}

export async function insertNewInvoice(data) {
  return new Promise(async (resolve, reject) => {
    await insertInvoice(data).then(async invoice => {
      await insertIntoFirestore(data, invoice);
      return resolve(invoice);
    }).catch(reason => {
      return reject(reason);
    });
  });
}

export async function closeAdvanceInvoice(data, rootInvoice) {
  return new Promise(async (resolve, reject) => {
    await insertInvoice(data).then(async invoice => {
      await insertIntoFirestore(data, invoice);
      await closeOpenAdvanceInvoice(data, invoice, rootInvoice);
      return resolve(invoice);
    }).catch(reason => {
      return reject(reason);
    });
  });
}

export async function closeOpenAdvanceInvoice(invoice, response, rootInvoice) {
  try {
    const timestamp = getTimestampFromSdc(response.sdcDateTime);
    let linkedInvoices = [...(rootInvoice.linkedInvoices || [])];
    let linkedRefund = [...(rootInvoice.linkedRefund || [])];
    linkedRefund = [...linkedRefund, ...linkedInvoices];
    await setDoc(doc(DB, "invoices", "advanceTransactionsHistory", AUTH.currentUser.uid,
        `${rootInvoice.timestamp}`), {
      ...rootInvoice,
      advanceAmount: rootInvoice.items.reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0),
      linkedInvoices: [parseInt(timestamp)],
      linkedRefund
    }, { merge: true });
    await deleteDoc(doc(DB, "invoices", `advanceTransactions`, AUTH.currentUser.uid,
        `${rootInvoice.timestamp}`));
  } catch (e) {
    putLogToFirebase("connectOpenAdvanceInvoice", "helper/firestore/openAdvance",
        [invoice, response, rootInvoice], e);
    throw new Error("7533");
  }
}

export async function connectNewAdvanceInvoice(data, rootInvoice, referentInvoiceTimestamp) {
  return new Promise(async (resolve, reject) => {
    await insertInvoice(data).then(async invoice => {
      await insertIntoFirestore(data, invoice);
      await connectOpenAdvanceInvoice(data, invoice, rootInvoice, referentInvoiceTimestamp);
      return resolve(invoice);
    }).catch(reason => {
      return reject(reason);
    });
  });
}

export async function getTaxCoreStatus(isAlreadyPosted = false) {
  return new Promise(async (resolve, reject) => {
    await getStatusFirebaseVpfr().then(responseVpfr => {
      return resolve({
        response: responseVpfr.data,
        type: "vpfr"
      });
    }).catch(async errorVpfr => {
      if (!isAlreadyPosted) {
        await loginOnError403(errorVpfr).then(_ => {
          getTaxCoreStatus(true).then(r => {
            return resolve({
              response: r.response,
              type: "vpfr"
            });
          });
        });
      } else {
        await getStatusLpfr().then(responsLpfr => {
          return resolve({
            response: responsLpfr.data,
            type: "lpfr"
          });
        }).catch(reason => {
          return reject(reason);
        });
      }
    });
  });
}

async function getStatusFirebaseVpfr() {
  const token = await AUTH.currentUser.getIdToken(true);
  const pac = getSessionSelectedLocation().PAC;
  return customAxios(token, pac).get("/api/v3/status");
}

export async function loginOnError403(e) {

  if (e?.response?.status === 403) {
    try {
      // const bytes = CryptoJS.AES.decrypt(getLoggedUser(), 'esiresir');
      // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      //await signInWithEmailAndPassword(AUTH, AUTH.currentUser.email, AUTH.password);
    } catch {
      console.error("nije proslo logovanje");
    }
  }
}

export async function getNewReports(from, to) {
  const token = await AUTH.currentUser.getIdToken(true);
  const pac = getSessionSelectedLocation().PAC;
  return axios.get(`https://europe-central2-esir-44ade.cloudfunctions.net/esir/report?from=${from}&to=${to}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
      "PAC": pac
    }
  });
}
