import {collection, getDoc, getDocs, query, Timestamp, where} from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";
import moment from "moment";

export async function getFacturesBySupplierUid(supplierUid) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("supplierUid", "==", supplierUid));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          uid: doc.id
        });
      });
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getAllDeletedOrdersFromFirestore(startDate, endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const startTimestamp = Timestamp.fromDate(startDate);
      const endTimestamp = Timestamp.fromDate(endDate);

      const querySnapshot = await getDocs(
          collection(DB, `deletedOrders/users/${AUTH.currentUser.uid}`)
      );

      const promises = [];

      querySnapshot.forEach((doc) => {
        const docTimestamp = Timestamp.fromMillis(Number(doc.id));

        if (docTimestamp >= startTimestamp && docTimestamp <= endTimestamp) {
          const promise = getDoc(doc.ref)
              .then((documentData) => {
                arr.push({
                  ...documentData.data(),
                  uid: doc.id,
                });
              })
              .catch((error) => {
                reject(error);
              });

          promises.push(promise);
        }
      });

      await Promise.all(promises);

      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}




export async function getAllFacturesByEndDateFromFirestore(endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("dateOfIssue", "<=", Timestamp.fromDate(endDate)));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          uid: doc.id
        });
      });
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getAllFacturesByDateFromFirestore(startDate, endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("dateOfIssue", ">", Timestamp.fromDate(startDate)),
        where("dateOfIssue", "<=", Timestamp.fromDate(endDate)));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getLastFactureByProductUid(productUid) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];

      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
        where("itemsIds", "array-contains", productUid));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      arr = arr.sort((a, b) => moment(a.dateOfIssue.toDate()).isBefore(moment(b.dateOfIssue.toDate())) ? 1 : -1);
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getLastFactureByIngredientUid(ingredientUid) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];
      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
          where("itemsIds", "array-contains", ingredientUid));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      arr = arr.sort((a, b) => moment(a.dateOfIssue.toDate()).isBefore(moment(b.dateOfIssue.toDate())) ? 1 : -1);
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getLastFactureByCostUid(costUid) {
  return new Promise(async (resolve, reject) => {
    try {
      let arr = [];

      const q = await query(collection(DB, `factures/users/${AUTH.currentUser.uid}`),
          where("itemsIds", "array-contains", costUid));
      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        arr.push({
          ...doc.data(),
          id: doc.id
        });
      });
      arr = arr.sort((a, b) => moment(a.dateOfIssue.toDate()).isBefore(moment(b.dateOfIssue.toDate())) ? 1 : -1);
      resolve(arr);
    } catch (e) {
      reject(e);
    }
  });
}
