import {useState} from 'react';
import * as Yup from 'yup';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Alert, IconButton, InputAdornment, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// auth
import {useAuthContext} from '../../../auth/useAuthContext';
// components
import Iconify from '../../../components/Iconify';
import FormProvider, {RHFTextField} from '../../../components/hook-form';
import {useLocales} from "../../../locales";

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
    const {login} = useAuthContext();
    const {translate} = useLocales();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required(translate("emailIsRequired")),
        password: Yup.string().required(translate("passwordIsRequired")),
    });

    const defaultValues = {
        email: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: {errors, isSubmitting, isSubmitSuccessful},
    } = methods;

    const onSubmit = async (data) => {
        try {
            await login(data.email.trim(), data.password.trim());
        } catch (error) {
            if (error.code === "auth/too-many-requests") {
                reset();
                setError('afterSubmit', {
                    ...error,
                    message: translate("tooManyRequests"),
                });
            } else {
                reset();
                setError('afterSubmit', {
                    ...error,
                    message: translate("invalidCredentials"),
                });
            }
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                <RHFTextField name="email" label={translate("email")}/>

                <RHFTextField
                    name="password"
                    label={translate("password")}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitSuccessful || isSubmitting}
                sx={{
                    mt: 1,
                    bgcolor: 'text.primary',
                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                    '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                    },
                }}
            >
                {translate("signIn")}
            </LoadingButton>
        </FormProvider>
    );
}
