import {getSessionSelectedLocation} from "../helper/session";
import {translate} from "../locales/i18n";

export const INVOICE_TYPE = {
    normal: "Normal", //PROMET
    proforma: "Proforma", //PREDRAČUN
    copy: "Copy", //KOPIJA
    training: "Training", //OBUKA
    advance: "Advance", //AVANS
}

const ESIR_NUMBER = "1181/1.0";
const ESIR_NUMBER_BA = "20/1.1";

export const SETTINGS_PAYMENTS = (translate) => [
    { name: "cash", value: PAYMENT_TYPE.cash, icon: "mdi:cash", displayName: translate("cash") },
    {
        name: "card",
        value: PAYMENT_TYPE.card,
        icon: "icon-park-solid:bank-transfer",
        displayName: translate("card")
    },
    {
        name: "check",
        value: PAYMENT_TYPE.check,
        icon: "material-symbols:fact-check-outline",
        displayName: translate("check")
    },
    {
        name: "mobile",
        value: PAYMENT_TYPE.mobileMoney,
        icon: "mdi:mobile-phone-sound",
        displayName: translate("mobileMoney")
    },
    { name: "voucher", value: PAYMENT_TYPE.voucher, icon: "mdi:voucher", displayName: translate("voucher") },
    {
        name: "virman",
        value: PAYMENT_TYPE.wireTransfer,
        icon: "mdi:credit-card-wireless-outline",
        displayName: translate("wireTransfer")
    },
    { name: "other", value: PAYMENT_TYPE.other, icon: "basil:other-1-outline", displayName: translate("otherPaymentType") }
];

export const PAYMENT_EXTRA_OPTIONS = [
    {
        value: "promet_bez_naknade",
        title: translate("nonFeeTransactions"),
        description: "Lorem impsum."
    }
    // {
    //   value: "reprezentacija",
    //   title: translate("representations"),
    //   description: "Lorem impsum."
    // }
];

export const INVOICE_TYPE_OPTIONS = (translate) => [
    {
        value: INVOICE_TYPE.normal,
        title: translate("trafficSale"),
        description: "Lorem impsum."
    },
    {
        value: INVOICE_TYPE.advance,
        title: translate("advance"),
        description: "Lorem impsum."
    },
    {
        value: INVOICE_TYPE.proforma,
        title: translate("proforma"),
        description: "Lorem impsum."
    }
];

export const getEsirNumber = () => {
    const location = getSessionSelectedLocation();
    if (location?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
        return ESIR_NUMBER_BA;
    }
    return ESIR_NUMBER;
};

export const TRANSACTION_TYPE = {
    sale: "Sale", //PRODAJA
    refund: "Refund" //REFUNDACIJA
}

export const PAYMENT_TYPE = {
    other: "Other", //DRUGO BEZGOTOVINSKO PLACANJE
    cash: "Cash", // GOTOVINA
    card: "Card", //PLATNA KARTICA
    check: "Check", //ČEK
    wireTransfer: "WireTransfer", //PRENOS NA RAČUN
    voucher: "Voucher", // VAUČER
    mobileMoney: "MobileMoney" // INSTANT PLAĆANJE
}

export const ALL_LICENSE = {
    webESIR: 0,
    webLPFR: 1,
    eFacture: 2,
    restaurant: 3
}

export const ONE_DAY_SECONDS_TIMEOUT = 86400000;
export const ONE_MINUTE_SECONDS_TIMEOUT = 60000;

// export const ESIR_NUMBER = "1181/1.0"

export const CHANGE_ITEM_QUANTITY = {
    reduce: 0,
    sum: 1,
}
export const INV_TYPES_FOR_REDUCE_QUANTITY = [INVOICE_TYPE.normal, INVOICE_TYPE.advance]
export const ITEM_UNIT_ONLY_STRINGS = ["kom", "l", "kg", "m2", "m3", "h", "tona", "par", "metar", "sum", "ostalo"]

export const RESTAURANT_GROUPS = [
    {id: 0, name: "Kuhinja"},
    {id: 1, name: "Šank"},
    {id: 2, name: "Usluge"},
    {id: 3, name: "Roba"},
    {id: 4, name: "Ostalo"},
]

export const COSTS_GROUPS = [
    {id: 0, name: "Grupa 1"},
    {id: 1, name: "Grupa 2"},
    {id: 2, name: "Grupa 3"},
    {id: 3, name: "Grupa 4"},
]
export const RESTAURANT_GROUPS_OBJECT = {
    kitchen: "Kuhinja",
    bar: "Šank"
}

export const RESTAURANT_ITEMS_CATEGORY = [
    {id: 0, name: "Hrana"},
    {id: 1, name: "Piće"},
    {id: 2, name: "Ostalo"},
]

export const ORDER_TYPE = {
    all: 0,
    kitchen: 1,
    bar: 2,
}

export const UNIT_ONLY_STRINGS = ["kom", "l", "kg", "m2", "h", "tona", "par", "m3", "metar", "sum"]

export const OPERATORS_GROUP = {
    admin: 0,
    manager: 1,
    operator: 2,
    accountant: 3,
    driver: 4,
    parking: 5,
    adminTourist: 6,
    operatorTourist: 7,
    chef: 8,
    waiter: 9,
    bar: 10
}
export const REPORTS_ROLES = [OPERATORS_GROUP.admin, OPERATORS_GROUP.manager, OPERATORS_GROUP.accountant]

export const CUSTOMER_TYPES = () => [
    {
        value: 10,
        description: "Domaće pravno lice identifikovano PIB-om"
    },
    {
        value: 11,
        description: "Domaće fizičko lice koje obavlja samostalnu delatnost identifikovano JMBG-om"
    },
    {
        value: 12,
        description: "Domaće pravno lice koje je identifikovano PIB-om i JBKJS-om"
    },
    {
        value: 13,
        description: "Fizičko lice - imalac penzionerske kartice"
    },
    {
        value: 16,
        description: "Fizičko lice identifikovano jedinstvenim brojem poljoprivrednog gazdinstva (BPG)"
    },
    {
        value: 20,
        description: "Domaće fizičko lice identifikovano ličnom kartom"
    },
    {
        value: 21,
        description: "Domaće fizičko lice identifikovano izbegličkom legitimacijom"
    },
    {
        value: 22,
        description: "Strano fizičko lice koje ima prijavljeni boravak u Srbiji - EBS"
    },
    {
        value: 23,
        description: "Domaće fizičko lice identifikovano pasošem"
    },
    {
        value: 30,
        description: "Strano fizičko lice koje se identifikuje pasošem"
    },
    {
        value: 32,
        description: "Strano fizičko lice - koje se identifikuje ličnom kartom iz Makedonije"
    },
    {
        value: 33,
        description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Crne Gore"
    },
    {
        value: 34,
        description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Albanije"
    },
    {
        value: 35,
        description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Bosne i Hercegovine"
    },
    {
        value: 40,
        description: "Poreski identifikacioni broj izdat u inostranstvu"
    }
]
export const TABLE_COLORS = {
    taken: "rgb(247, 0, 0, 0.5)",
    free: "transparent",
    reserved: "rgb(247, 0, 0, 0.5)",
    notSubmitted: "rgb(100, 0, 0, 0.5)",
    borderColor: "#7CFC00;#61c204;#7CFC00 "
}

export const STATUS_OPTIONS = ['SVI', 'AKTIVAN', 'PASIVAN'];

export const KEYBOARD_TYPE = {num: 0, normal: 1}

export const SHAPES = {rect: "rect", circle: "circle"}

export const CALC_OPERATION = {SUM: "SUM", MINUS: "MINUS"}

export const ORDER_TABS = {create: 1, allOrders: 2, reservations: 3}

export const ESIR_NUMBERS = {ba: "20/1.1"}
