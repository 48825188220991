import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {child, get} from "firebase/database";

export const fetchEsirBalance = createAsyncThunk("fetchEsirBalance", async () => {
    return (await get(child(dbRef, `userFinance/${AUTH.currentUser.uid}/balance`))).val()
})

export const fetchAllEsirInvoices = createAsyncThunk("fetchAllEsirInvoices", async () => {
    let arr = [];
    let objArr = (await get(child(dbRef, `userFinance/${AUTH.currentUser.uid}/invoices`))).val()
    if (objArr) {
        for (const key of Object.keys(objArr)) {
            arr.push({
                id: key,
                ...objArr[key]
            })
        }
    }
    return arr;
})

const initialState = {
    invoices: [],
    balance: 0,
    loading: false
}

export const slice = createSlice({
    name: 'esirInvoices',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllEsirInvoices.pending]: (state) => {
            state.loading = true
        },
        [fetchAllEsirInvoices.fulfilled]: (state, {payload}) => {
            state.invoices = payload;
            state.loading = false
        },
        [fetchAllEsirInvoices.rejected]: (state) => {
            state.loading = false
        },
        //fetchEsirBalance
        [fetchEsirBalance.pending]: (state) => {
            state.loading = true
        },
        [fetchEsirBalance.fulfilled]: (state, {payload}) => {
            state.balance = payload;
            state.loading = false
        },
        [fetchEsirBalance.rejected]: (state) => {
            state.loading = false
        }
    }
});

export default slice.reducer