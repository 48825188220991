import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {child, get, push, remove, set} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import moment from "moment/moment";

export const fetchAllUserMessages = createAsyncThunk('fetchAllUserMessages', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox`))).forEach(child => {
        let childValue = child.val();
        arr.push({
            uid: child.key,
            ...childValue
        });
    });
    return arr;
})

export const setNotificationAsRead = createAsyncThunk('setNotificationAsRead', async (data) => {
    return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox/${data.uid}`), {
        body: data.body,
        date: moment().format("YYYY-MM-DD"),
        isRead: true,
        title: data.title
    })
})

export const addNotification = createAsyncThunk("addNotification", async (payload) => {
    return push(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox`), {...payload});
})

export const deleteNotifications = createAsyncThunk("deleteNotifications", async () => {
    return await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox`));
})

const initialState = {
    messages: [],
}

export const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addMessage: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state.messages)]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index === -1) {
                    arr.push(payload)
                    state.messages = arr;
                }
            }
        },
        changeMessage: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index !== -1) {
                    arr[index] = payload;
                    state.messages = arr;
                }
            }
        },
        deleteMessage: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index !== -1) {
                    arr.splice(index, 1)
                    state.messages = arr;
                }
            }
        }
    },
    extraReducers: {
        [fetchAllUserMessages.fulfilled]: (state, {payload}) => {
            state.messages = payload
        },
        [deleteNotifications.fulfilled]: (state) => {
            state.messages = []
        },
        [setNotificationAsRead.fulfilled]: (state, {meta}) => {
            let newData = {
                ...meta.arg,
                isRead: true
            };
            let arr = [...current(state).messages]
            let index = arr.findIndex((notification) => notification.uid === newData.uid)
            if (index !== -1) {
                arr[index] = newData;
            }
            state.messages = arr;
        },
        [addNotification.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.messages)]
            arr.push(payload)
            state.messages = arr;
        }
    }
});
export const {addMessage, changeMessage, deleteMessage} = slice.actions
export default slice.reducer;


