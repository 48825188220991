import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNewReports } from "../../api/vpfr";
import { getSessionOperator } from "../../helper/session";
import { REPORTS_ROLES } from "../../constants";
import { parseNewReport } from "../../helper/report/newReport";

export const fetchReportForDate = createAsyncThunk("fetchReportForDate", async ({ dateFrom, dateTo, location }, { getState }) => {
  const state = getState();
  const { dateFrom: dateFromB, dateTo: dateToB, derived: derivedB, countSummary: countSummaryB } = state.newReports;
  let operator = getSessionOperator();
  if (REPORTS_ROLES.includes(Number(operator.group))) {
    operator = undefined;
  } else {
    operator = operator.username;
  }
  let forProcessing;
  let countSummary = countSummaryB;
  let derived = derivedB;
  if (derivedB && dateFromB === dateFrom && dateToB === dateTo) {
    forProcessing = derivedB;
  } else {
    const result = (await getNewReports(dateFrom, dateTo)).data;
    forProcessing = result.derived || {};
    derived = result.derived || {};
    countSummary = result?.data?.summary?.count || {};
  }
  if (location) {
    forProcessing = { [location]: forProcessing[location] };
    if (!forProcessing) {
      forProcessing = {};
    }
  }
  const report = parseNewReport(forProcessing, operator);

  return {
    derived: derived,
    report: report,
    countSummary: countSummary
  };
});

const initialState = {
  dateTo: undefined,
  dateFrom: undefined,
  derived: {},
  countSummary: {},
  report: {
    itemsSale: [],
    itemsRefund: [],
    payment: [],
    cashier: [],
    tax: [],
    summary: {
      basicRefund: 0,
      basicSale: 0,
      invoiceCountRefund: 0,
      invoiceCountSale: 0,
      taxRefund: 0,
      taxSale: 0,
      total: 0,
      totalRefund: 0,
      totalSale: 0
    }
  },
  loading: false
};

export const newReportsSlice = createSlice({
  name: "newReports",
  initialState,
  reducers: {
    resetReport: (state) => {
      state.report = initialState.report;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportForDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReportForDate.fulfilled, (state, { payload, meta }) => {
        const { derived, report, countSummary } = payload;
        const { dateTo, dateFrom } = meta.arg;
        state.dateTo = dateTo;
        state.dateFrom = dateFrom;
        state.report = report;
        state.derived = derived;
        state.countSummary = countSummary;
        state.loading = false;
      })
      .addCase(fetchReportForDate.rejected, (state, action) => {
        console.error("rejected", action);
        state.loading = false;
      });
  }
});


export const { resetReport } = newReportsSlice.actions;
export const newReportsReducer = newReportsSlice.reducer;
