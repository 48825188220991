import React, {createContext, useContext, useState} from "react";
import {setSessionSelectedLocation} from "../helper/session";
import {CONFIG_BY_LOCATION} from "../config";

const LocationContext = createContext(null);

export const LocationProvider = ({ children }) => {
    const [locationContext, setLocation] = useState(null);
    const [locationState, setLocationState] = useState(CONFIG_BY_LOCATION.rs);

    const setLocationContext = (newLocation) => {
        if (newLocation?.tapURL === "tap.sandbox.suf.poreskaupravars.org") {
            setLocationState(CONFIG_BY_LOCATION.ba);
        } else {
            setLocationState(CONFIG_BY_LOCATION.rs);
        }

        setSessionSelectedLocation(newLocation);
        setLocation(newLocation);
    };


    return (
        <LocationContext.Provider value={{ locationContext, locationState, setLocation: setLocationContext }}>
            {children}
        </LocationContext.Provider>
    );
};

function useLocationContext() {
    const context = useContext(LocationContext);

    if (context === null) {
        throw new Error('useLocationContext must be used within a LocationProvider');
    }

    return context;
}

export { LocationContext, useLocationContext };
