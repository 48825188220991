import {createSlice} from "@reduxjs/toolkit";
// utils
import {dispatch} from '../store';
import {child, get} from "firebase/database";
import {dbRef} from "../../auth/FirebaseContext";
import {removeSessionOperator, setSessionOperator} from "../../helper/session";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    userApiKey: null,
    userInfo: null,
    operatorInfo: null,
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, {payload}) {
            state.isLoading = false;
            state.error = payload;
        },

        // GET USER
        getUserSuccess(state, {payload}) {
            state.isLoading = false;
            state.userApiKey = payload
        },

        // GET USER INFO
        getUserInfoSuccess(state, {payload}) {
            localStorage.setItem('userInfo', JSON.stringify(payload));
            state.userInfo = payload
            state.isLoading = false;
        },

        // SET OPERATOR
        setOperatorSuccess(state, {payload}) {
            setSessionOperator(payload)
            state.operatorInfo = payload
            state.isLoading = false;
        },

        logOutOperator: (state) => {
            removeSessionOperator()
            state.operatorInfo = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export async function getUserInfo(user) {
    dispatch(slice.actions.startLoading());
    try {
        const tin = (await get(child(dbRef, `users/${user.uid}/profile/pub`))).val()
        let userInfo = (await get(child(dbRef, `public/businesses/${tin.TIN}`))).val()
        const apiKey = (await get(child(dbRef, `users/${user.uid}/protected/efakture/apiKey`))).val()
        const isProduction = (await get(child(dbRef, `users/${user.uid}/protected/efakture/isProduction`))).val()
        userInfo.PIB = tin.TIN
        userInfo.apiKey = apiKey
        userInfo.isProduction = isProduction || false
        dispatch(slice.actions.getUserInfoSuccess(userInfo));
    } catch (e) {
        dispatch(slice.actions.hasError(e))
    }
}

export function setOperatorInfo(operator) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setOperatorSuccess(operator));
        } catch (e) {
            dispatch(slice.actions.hasError(e))
        }
    }

}
