import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {child, get, push, update} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";

export const fetchAllCosts = createAsyncThunk('fetchAllCosts', async () => {
    let costs = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs`))).forEach(cost => {
        costs.push({
            ...cost.val(),
            uid: cost.key,
        })
    })
    return costs
})

export const addNewCost = createAsyncThunk('addNewCost', async (payload) => {
    delete payload.avatarUrl;
    const costKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs`),
        {...payload, inactive: false});
    return {...payload, uid: costKey.key}
})

export const updateCost = createAsyncThunk('updateCost', async (payload) => {
    delete payload.avatarUrl;
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${payload.uid}`),
        {...payload, inactive: false});
    return {...payload, inactive: false}
})

export const deleteCost = createAsyncThunk('deleteCost', async(payload) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${payload.uid}`),
        {...payload, inactive: !payload.inactive});
    return {
        ...payload,
        inactive: !payload.inactive
    }
})


const initialState = {
    costsList: [],
    cost: undefined,
    loadingCosts: false,
    error: false
}

export const costsSlice = createSlice({
    name: 'costs',
    initialState,
    extraReducers: {
        [fetchAllCosts.pending]: (state) => {
            state.loadingCosts = true;
        },
        [fetchAllCosts.fulfilled]: (state, action) => {
            state.costsList = action.payload
            state.loadingCosts = false;
        },
        [fetchAllCosts.rejected]: (state) => {
            state.loadingCosts = false;
        },
        [addNewCost.pending]: (state) => {
            state.loadingCosts = true;
        },
        [addNewCost.fulfilled]: (state, {payload}) => {
            let arr =  [...current(state.costsList)];
            arr.push(payload);
            state.costsList = arr;
            state.loadingCosts = false;
        },
        [addNewCost.rejected]: (state) => {
            state.loadingCosts = false;
            state.error = undefined;
        },
        [updateCost.pending]: (state) => {
            state.loadingCosts = true;
        },
        [updateCost.fulfilled]: (state, {payload}) => {
            let arr =  [...current(state.costsList)];
            const index = arr.findIndex(i => i.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.costsList = arr
            }
            state.loadingCosts = false;
        },
        [updateCost.rejected]: (state) => {
            state.loadingCosts = false;
            state.error = undefined;
        },
        [deleteCost.pending]: (state) => {
            state.loading = true;
        },
        [deleteCost.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.costsList)]
            const index = arr.findIndex(i => i.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.costsList = arr
            }
            state.error = undefined
        },
        [deleteCost.rejected]: (state) => {
            state.loading = false;
        },
    }
})

export const costsReducer = costsSlice.reducer
