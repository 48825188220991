import { useTranslation } from "react-i18next";
// components
//
import { allLangs, defaultLang } from "./config-lang";
import { getSessionLanguage, setSessionLanguage } from "../helper/session";
import { useSettingsContext } from "../components/settings";
import {useLocationContext} from "../context/Location";

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { onChangeDirectionByLang } = useSettingsContext();
  const langStorage = getSessionLanguage() || "";
  const {locationState} = useLocationContext();
  // const index= locationState.short === "RS" ? 0 : 1
  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang(0);

  const handleChangeLanguage = async (newlang) => {
    await i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
    setSessionLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    currency: currentLang?.currency,
    allLangs
  };
}
