import {
    child,
    get,
    increment,
    limitToFirst,
    onValue,
    orderByKey,
    query,
    ref,
    startAfter,
    update
} from "firebase/database";
import {
    getAllOfflineInvoiceForReportBySdcDateTime,
    getLastInsertedOfflineItem,
    putItemOffline,
    storeErrorToOffline,
    updateItemOffline,
    updateQuantityOffline
} from "../../store/offlineDb";
import {getAllFacturesByEndDateFromFirestore} from "../firestore/factures";
import moment from "moment";
import {CHANGE_ITEM_QUANTITY, TRANSACTION_TYPE} from "../../constants";
import {analyticsFB, AUTH, DB, dbRef} from "../../auth/FirebaseContext";
import {addMessage, addNotification} from "../../redux/slices/notifications";
import {formatQuantity} from "../../utils/other";
import {logEvent} from "firebase/analytics";

export async function insetAllItemsOffline(setLoaded) {
    return new Promise(async (resolve, reject) => {
        await getItem(null, resolve, reject, setLoaded);
    });
}

export async function getItem(lastData, resolve, reject, setLoaded) {
    if (lastData === null) {
        lastData = await getLastInsertedOfflineItem();
    }
    try {
        const userItemsDbRef = ref(DB, `users/${AUTH.currentUser.uid}/private/items`);
        await get50RecordsOnRealTimeDB(userItemsDbRef, lastData ? lastData.uid : null, []).then(async value => {
            // 1000 loaded * 100 / 1660
            let i = 1;
            for (const item of value) {
                setLoaded(Math.floor(((i * 100)) / value.length));
                let publicItem = (await get(child(dbRef, `public/items/${item.uid}`))).val();
                await putItemOffline({
                    ...publicItem,
                    ...item
                });
                i++;
            }
        });
        resolve(false);
    } catch (e) {
        reject(undefined);
    }
}

export async function get50RecordsOnRealTimeDB(dbRef, lastData, arr) {
    return new Promise((async resolve => {
        await repeat(dbRef, lastData, arr, resolve);
    }));
}

async function repeat(dbRef, lastData, arr, resolve) {
    let q;
    if (lastData) {
        q = query(dbRef, orderByKey(), limitToFirst(200), startAfter(lastData));
    } else {
        q = query(dbRef, orderByKey(), limitToFirst(200));
    }
    await onValue(q, async (snapshot) => {
        let snapshotSize = snapshot.size;
        if (snapshotSize === 0) {
            return resolve(arr);
        } else {
            let i = 0;
            snapshot.forEach(child => {
                let parsedChild = child.val();
                arr.push({
                    ...parsedChild,
                    id: i,
                    uid: child.key
                });
                if (i === snapshotSize - 1) {
                    repeat(dbRef, child.key, arr, resolve);
                }
                i = i + 1;
            });
        }
    });
}

export async function changeIngQuantity(uid, quantity, operation) {
    try {
        let totalQty = 0;
        if (operation === CHANGE_ITEM_QUANTITY.reduce) {
            totalQty = -formatQuantity(quantity);
        } else {
            totalQty += formatQuantity(quantity);
        }
        await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${uid}`), {
            quantity: increment(totalQty)
        });
        // dispatch(changeReduxIngQuantity({
        //   uid: uid,
        //   quantity: totalQty
        // }));
        await updateQuantityOffline(uid, totalQty, "ingrediants");
    } catch (e) {
        console.error("changeIngQuantity", e);
        storeErrorToOffline("chngeIngQuantity", "helper/realtimeDatabase/index.js", [uid, quantity,
            operation], e?.toString());
    }
}

export async function changeItemQuantity(uid, quantity, operation, flag, dispatch) {
    if (uid === undefined || uid === null) {
        return;
    }
    try {
        let item = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
        item = {
            ...item,
            uid: uid
        };
        if (item.ingredients) {
            await changeItemIngredientsQty(item, quantity, operation, dispatch);
        } else {
            let itemQuantity;
            if (item.quantity) {
                itemQuantity = operation === CHANGE_ITEM_QUANTITY.reduce ?
                    Number(Number(item.quantity) - Number(parseFloat(quantity).toFixed(3)))
                    : Number(Number(item.quantity) + Number(parseFloat(quantity).toFixed(3)));
            } else {
                itemQuantity = quantity;
            }
            await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`), {
                ...item,
                quantity: itemQuantity
            });
            // dispatch(changeReduxItemQuantity({
            //   uid: uid,
            //   quantity: itemQuantity
            // }));
            await updateItemOffline({
                ...item,
                quantity: itemQuantity
            });
            //addAlertMessage(item, quantity, true, dispatch);
        }
    } catch (e) {
        storeErrorToOffline("changeItemQuantity", "helper/realtimeDatabase/index.js", [uid, quantity,
            operation], e?.toString());
    }
}

//TODO minimalQuantity dodati
async function changeItemIngredientsQty(item, quantity, operation, dispatch) {
    let ingredients = item.ingredients;
    for (const ingredientUid of Object.keys(item.ingredients)) {
        let needForMakeQty = ingredients[ingredientUid].quantity;
        let ingItem = await
            (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientUid}`))).val();
        let ingQty;
        if (operation === CHANGE_ITEM_QUANTITY.reduce) {
            ingQty = parseFloat((Number(ingItem.quantity) - (Number(quantity) *
                Number(parseFloat(needForMakeQty))).toFixed(3)));
        } else {
            ingQty = Number((Number(ingItem.quantity) + (Number(quantity) *
                Number(parseFloat(needForMakeQty))).toFixed(3)));
        }
        await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientUid}`),
            {
                ...ingItem,
                quantity: ingQty
            });
        // dispatch(changeReduxIngQuantity({
        //   uid: ingredientUid,
        //   quantity: ingQty
        // }));
        //addAlertMessage(ingItem, quantity, false, dispatch);
    }
}

const addAlertMessage = (item, quantity, isItem, dispatch) => {
    if (dispatch) {
        if (item?.minimalQuantity !== undefined && ((item.quantity - quantity) < item?.minimalQuantity)) {
            let code = isItem ? "Proizvod sa šifrom: " : "Sastojak sa šifrom: ";
            let name = item.name ? item.name : code + item.code;
            const obj = {
                body: name + " ostalo još: " + quantity,
                date: new Date().toString(),
                isRead: false,
                title: " Premala količina: " + name
            }
            dispatch(addNotification(obj)).unwrap.then(_ => {
                dispatch(addMessage(obj));
            })
        }
    }
};

export async function getProductFromRealtimeDbByUid(uid) {
    const publicItem = await (await get(child(dbRef, `public/items/${uid}`))).val();
    const privateItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
    return {
        ...publicItem,
        ...privateItem
    };
}

export async function getCostsFromRealtimeDbByUid(uid) {
    return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${uid}`))).val();
}

export async function getIngredientFromRealtimeDbByUid(uid) {
    return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${uid}`))).val();
}

// PROVERAVA DA LI IMA DOVOLJNO SASTOJAKA ZA ODREDJEN PROIZVOD
export async function checkIfProductHaveIngredients(item, quantity) {
    let ingredients = item.ingredients;
    let ingredientsKeys = Object.keys(item.ingredients);
    let response = {
        haveQuantity: true,
        productName: ""
    };
    for (const ingredientsKey of ingredientsKeys) {
        let needForMakeQty = ingredients[ingredientsKey].quantity;
        let ingItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientsKey}`))).val();
        let ingQty = parseFloat((parseFloat(ingItem.quantity) - (parseFloat(quantity) * parseFloat(needForMakeQty))).toFixed(3));
        if (ingQty < 0) {
            response = {
                haveQuantity: false,
                productName: ingItem.name
            };
            return response;
        }
    }
    return response;
}

// preneta kolicina = (sve fakture do tog dana - svi izdati racuni do tog dana)
// TODO Sta raditi za iteme koji su refundirani?
export async function itemsStateOnDate(date) {
    try {
        let dateForBefore = moment(date).set("hour", 0).set("minute", 0).set("second", 0);
        let facturesBefore = await getAllFacturesByEndDateFromFirestore(dateForBefore.toDate());
        let allInvoicesBefore = await getAllOfflineInvoiceForReportBySdcDateTime(
            moment(new Date(2020, 1, 1, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ss"),
            dateForBefore.format("YYYY-MM-DDTHH:mm:ss"));
        let allItems = {};
        for (const invoice of allInvoicesBefore) {
            if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                for (const item of invoice.items) {
                    if (item.uid) {
                        allItems[item.uid] = allItems[item.uid] ? allItems[item.uid] - Number(item.quantity) : -Number(item.quantity);
                    }
                }
            }
        }
        for (const facture of facturesBefore) {
            for (const item of facture.items) {
                allItems[item.uid] = allItems[item.uid] ? allItems[item.uid] + Number(item.quantity) : Number(item.quantity);
            }
        }
        return allItems;
    } catch (e) {
        console.error("itemsStateOnDate", e);
        return {};
    }
}

export async function itemsAveragePurchasePrice(date) {
    try {
        let dateForBefore = moment(date).set("hour", 0).set("minute", 0).set("second", 0);
        let facturesBefore = await getAllFacturesByEndDateFromFirestore(dateForBefore.toDate());
        let items = [];
        for (const facture of facturesBefore) {
            for (const item of facture.items) {
                let index = items.findIndex(obj => obj.uid === item.uid);
                if (index === -1) {
                    items.push({
                        uid: item.uid,
                        sumPrices: Number(item.purchasePrice),
                        counter: 1
                    });
                } else {
                    items[index] = {
                        ...items[index],
                        sumPrices: items[index].sumPrices + Number(item.purchasePrice),
                        counter: items[index].counter + 1
                    };
                }
            }
        }
        let objItems = {};
        for (const item of items) {
            objItems[item.uid] = item.sumPrices / item.counter;
        }
        return objItems;
    } catch (e) {
        console.error("itemAveragePurchasePrice", e);
        return {};
    }
}

export const itemsAnalytics = (componentName) => {
    const eventData = {
        user_uid: AUTH.currentUser.uid,
        screen: componentName,
    };
    logEvent(analyticsFB, 'read_items_webKelner', eventData);
}