import {Box, Button, Dialog, DialogTitle, Stack, Typography} from "@mui/material";
import Scrollbar from "./Scrollbar";
import {dispatch} from "../redux/store";
import {deleteNotifications} from "../redux/slices/notifications";
import {useLocales} from "../locales";

export default function AllNotificationsDialog({open, onClose, messages}) {

    const {translate} = useLocales();

    const handleDeleteNotifications = () => {
        dispatch(deleteNotifications())
    }
    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <DialogTitle>{translate("notificationList")}</DialogTitle>
            </Box>

            <Scrollbar>
                <Box
                    sx={{
                        padding: 2,
                        maxHeight: 700
                    }}>

                    <Stack spacing={1} justifyContent="space-between"
                           sx={{my: 1}}>
                        {messages.map((notification) =>
                            <NotificationItem notification={notification}/>)}
                    </Stack>
                </Box>
            </Scrollbar>
            <Button color="primary" onClick={handleDeleteNotifications}>{translate("removeAllNotifications")}</Button>
        </Dialog>
    );
}

const NotificationItem = (data) => {

    return (
        <Box border="1px solid lightGrey" borderRadius={1} padding={2} width={1}>
            <Typography variant="subtitle1" color="text.disabled">{data.notification.title}</Typography>
            <Typography variant="inherit">{data.notification.body}</Typography>
            <Typography fontWeight={500} variant="caption">{data.notification.date}</Typography>
        </Box>
    )
}
