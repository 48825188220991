import { Helmet } from 'react-helmet-async';
// sections
import Login from './LoginAuth';
import {useLocales} from "../../../locales";

// ----------------------------------------------------------------------

export default function LoginPage() {
    const {translate} = useLocales();
    return (
        <>
            <Helmet>
                <title>{translate("waiterLoginTitle")}</title>
            </Helmet>

            <Login />
        </>
    );
}
